import { Pipe, PipeTransform } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { BookSeries } from 'src/app/models/book-series.model';

@Pipe({
    name: 'amazonLink'
})

export class AmazonLinkPipe implements PipeTransform {
    transform(bookOrSeries: Book | BookSeries): string {
        if ("links" in bookOrSeries) { //is a book
            if (bookOrSeries.shortAmazonLink) {
                return bookOrSeries.shortAmazonLink;
            } else if (bookOrSeries.links.amazon) {
                return bookOrSeries.links.amazon;
            } else if (bookOrSeries.asin) {
                return `https://amazon.com/dp/${bookOrSeries.asin}`;
            } else {
                return "";
            }
        } else {
            if (bookOrSeries.link) {
                return bookOrSeries.link;
            } else if (bookOrSeries.asin) {
                return `https://amazon.com/dp/${bookOrSeries.asin}`;
            } else {
                return "";
            }
        }
    }
}