import {  NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './modules/shared/shared.module';
import { BooksModule } from './modules/books/books.module';
import { DiscussionModule } from './modules/discussion/discussion.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AnonymousPersistentState } from './services/anonymous-persistent-state';
import { FollowService } from './modules/books/services/follow.service';
import { SubscriptionsModule } from './modules/subscriptions/subscriptions.module';
import { DiscussionShareService } from './modules/discussion/discussionShare.service';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { PWAService } from './pwa/pwa.service';
import { ChatWidgetService } from './services/chatWidget.service';
import { RecommendationsService } from './services/recommendations.service';

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        AppRoutingModule,
        BooksModule,
        SharedModule,
        NgbModalModule
    ],
    providers: [
        provideClientHydration(
            withHttpTransferCacheOptions({
                includePostRequests: true
            })
        ), 
        AnonymousPersistentState,
        FollowService,
        DiscussionShareService,
        PWAService,
        ChatWidgetService,
        RecommendationsService,
        provideHttpClient(withFetch())
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
