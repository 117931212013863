<header class="d-flex justify-content-between align-items-center" *ngIf="!isSplashScreen">
    <div class="d-flex align-items-center c-gap-40">
        <span class="logo-container" [ngClass]="{'d-none': scrolledPastHeader, 'd-block': !scrolledPastHeader}">
            <ng-container [ngTemplateOutlet]="standardLogo"></ng-container>
        </span>
        <span class="logo-container" [ngClass]="{'d-none': !scrolledPastHeader, 'd-block': scrolledPastHeader}">
            <ng-container [ngTemplateOutlet]="otherLogo"></ng-container>
        </span>
        @if (!linksDisabled) {
            <a [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" class="navlink d-none d-lg-block">Home</a>
            <a [routerLink]="['/library/shelves/free']" [routerLinkActive]="'active'" class="navlink d-none d-lg-block">Your Library</a>
            <a [href]="blogUrl" (click)="trackBlogVisit()" target="_blank" class="navlink d-none d-lg-block">Blog</a>
            <a href="https://authors.crewfiction.com" target="_blank" class="for-authors d-none d-lg-block">For Authors</a>
        }
    </div>
    <div class="d-flex align-item-center c-gap-12 c-gap-lg-32" *ngIf="!linksDisabled">
        <search-bar></search-bar>
        @if (isLoggedIn) {
            <a class="d-flex d-lg-none mobile-link" [routerLink]="['/library/shelves/free']" [routerLinkActive]="'active'">
                <svg width="24" height="24" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.25 16.5V1.5" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.75 1.5V16.5" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.25 1.53223L14.2518 16.467" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Your Library</span>
            </a>
        } @else {
            <button class="btn btn-dark btn-round login-mobile d-flex d-lg-none" (click)="login()">Login</button>
        }
        <div class="d-none d-lg-block">
            @if (isLoggedIn) {
                <div class="dropdown" [ngClass]="{'logged-in': isLoggedIn}" data-bs-toggle="dropdown" data-bs-target="dropdown">
                    <img class="dropdown-toggle profile-picture-internal" *ngIf="user?.profile?.images?.thumbnail" [src]="user?.profile?.images?.thumbnail" alt="Profile Picture">
                    <div class="arrow">
                        <feather-icon icon="chevron-down" width="14" height="14"></feather-icon>
                    </div>
                    <div id="dropdown">
                        <ul class="dropdown-menu">
                            <li [routerLink]="['/account-settings']">
                                <a class="dropdown-item pointy">
                                    Account Settings
                                </a>
                            </li>
                            <li (click)="logout()">
                                <a class="dropdown-item pointy">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            } @else {
                <button class="btn btn-dark btn-large btn-round login" (click)="login()">Login</button>
            }
        </div>
        <div class="d-block d-lg-none">
            @if (isLoggedIn) {
                <div class="dropdown logged-in" data-bs-toggle="dropdown" data-bs-target="dropdown-mobile">
                    <img class="profile-picture-internal dropdown-toggle" *ngIf="user?.profile?.images?.thumbnail" [src]="user?.profile?.images?.thumbnail" alt="Profile Picture">
                    <div class="arrow">
                        <feather-icon icon="chevron-down" width="14" height="14"></feather-icon>
                    </div>
                </div>
            } @else {
                <a class="mobile-link" data-bs-toggle="dropdown" data-bs-target="dropdown-mobile">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 17H4.5" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.5 12H19.5" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.5 7H19.5" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Menu</span>
                </a>
            }
            <div id="dropdown-mobile">
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item pointy" [routerLink]="['/']">Home</a></li>
                    @if (!isLoggedIn) {
                        <li><a class="dropdown-item pointy" [routerLink]="['/library/shelves/free']">Your Library</a></li>
                    }
                    <li><a [href]="blogUrl" target="_blank" class="dropdown-item pointy" (click)="trackBlogVisit()">Blog</a></li>
                    @if (isLoggedIn) {
                        <li [routerLink]="['/account-settings']">
                            <a class="dropdown-item pointy">
                                Account Settings
                            </a>
                        </li>
                        <li (click)="logout()">
                            <a class="dropdown-item pointy">Logout</a>
                        </li>
                    }
                    <li>
                        <span class="dropdown-item pointy">
                            <a href="https://authors.crewfiction.com/" target="_blank" class="for-authors">For Authors</a>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>

<ng-template #standardLogo>
    @if (!linksDisabled) {
        <a class="logo no-decoration d-flex align-items-center no-select" [routerLink]="['/']">
            <img src="/client/assets/logos/cf-logo-1-1.svg"
                alt="logo" height="35" style="margin-top: -10px;">
        </a>
    } @else {
        <a class="logo no-decoration d-flex align-items-center no-select">
            <img src="/client/assets/logos/cf-logo-1-1.svg"
                alt="logo" height="35" style="margin-top: -10px;">
        </a>
    }
</ng-template>

<ng-template #otherLogo>
    @if (!linksDisabled) {
        <a class="d-flex c-gap-8 no-decoration no-select" [routerLink]="['/']">
            <span>
                <img src="/client/assets/logos/cf-watermark.svg"
                alt="logo" height="32">
            </span>
            <span class="home-fiction-badge">The new home for fiction</span>
        </a>
    } @else {
        <a class="d-flex c-gap-8 no-decoration no-select">
            <span>
                <img src="/client/assets/logos/cf-watermark.svg"
                alt="logo" height="32">
            </span>
            <span class="home-fiction-badge">The new home for fiction</span>
        </a>
    }
</ng-template>