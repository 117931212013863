import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import emailSpellChecker from '@zootools/email-spell-checker';
import { Book } from 'src/app/models/book.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { EmbeddedAuthService } from '../../auth/services/embedded-auth.service';
import { Subscription } from 'rxjs';
import { FollowService } from '../../books/services/follow.service';
import { LibraryStateService } from 'src/app/state/library.state.service';
import { Author } from 'src/app/models/author.model';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'free-book-form',
    templateUrl: './free-book-form.component.html',
    styleUrl: './free-book-form.component.scss',
    animations: [
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        )
    ]
})
export class FreeBookFormComponent implements OnInit, OnDestroy, OnChanges {

    private readonly _subs: Subscription[] = [];
    isLoggedIn: boolean = false;
    hasFreeBook = false;
    mainAuthor?: Author | null;
    loading: boolean = false;
    emailSuggestionError = "";

    @Input() book!: Book | null;
    @Input() data!: {
        recommendedBooks?: Book[],
        altCoverIndex?: string,
        altReadableSlug?: string,
        acValuePostfix?: string
        ctaText?: string
        placeholder?: string
    };
    @Input()
    author: Author | null = null;

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    constructor(
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _analyticsService: AnalyticsService,
        private readonly _router: Router,
        private readonly _authService: EmbeddedAuthService,
        private readonly _followService: FollowService,
        private readonly _libraryStateService: LibraryStateService,
        private readonly _chatWidgetService: ChatWidgetService,
        private readonly _utilitiesService: UtilitiesService
    ) {

    }

    ngOnInit(): void {
        this._subs.push(this._authService.isLoggedIn$.subscribe((status) => {
            this.isLoggedIn = status;
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.book && changes.book.currentValue) {
            const main = this.book!.authors?.find(
                (author) => author.slug === this.book!.mainAuthorSlug
            );
            if (main) {
                this.mainAuthor = main;
            }
            else {
                this.mainAuthor = this.book!.authors ? this.book!.authors[0] : null;
            }
        }
        if (changes.author && changes.author.currentValue) {
            this.mainAuthor = changes.author.currentValue;
        }
    }

    ngOnDestroy(): void {
        this._subs.forEach(sub => sub.unsubscribe());
    }

    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    async readNow() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;
        this._anonymousPersistentState.saveEmail(email);

        if (!this.book) {
            // assume simple sign up form
            this._analyticsService.identifyAndTrack({ email, traits: { signupReason: { type: "generic" } } }, {
                event: "simple_form",
                params: { authorSlug: this.mainAuthor!.slug, type: "simple_form" }
            });
            // TODO: Add redirect
        }
        else {
            let acValue = `${this.book.mainAuthorSlug}--${this.book.slug}`; //activeCampaign encode
            //some extra info to know when it's from the landing page or the book page
            if (this._router.url.match("/pages/")) {
                acValue += "--pages";
                if (this.data.acValuePostfix) {
                    acValue += `${this.data.acValuePostfix}`;
                }
            }
            this._analyticsService.identifyAndTrack({ email, traits: { signupReason: { type: "free_book_flow", book: this.book.slug } } }, {
                event: "get_free_book",
                params: { book: this.book, acValue, type: "free_book", placement: "book_page" }
            });
    
            this._analyticsService.trackClientOnly({ event: "get_free_book_c", params: { book: this.book, acValue, type: "free_book", placement: "book_page" } });
    
            //start flow
            this._anonymousPersistentState.saveFreeBook(this.book.slug, {
                altCoverIndex: this.data.altCoverIndex && Number(this.data.altCoverIndex) >= 0 ? Number(this.data.altCoverIndex) : undefined,
                altReadableSlug: this.data.altReadableSlug
            });
            if (this.isLoggedIn) {
                await this._followService.followAuthor({ source: "book_page", book: this.book.slug });
                if (!this.hasFreeBook) {
                    this._libraryStateService.addBookToFreeShelf(this.book.id!, {
                        altCoverIndex: this.data.altCoverIndex && Number(this.data.altCoverIndex) >= 0 ? Number(this.data.altCoverIndex) : undefined,
                        altReadableSlug: this.data.altReadableSlug
                    });
                }
            }
            await this._router.navigate([`/authors/${this.mainAuthor!.slug}/recommends`], {
                state: {
                    recommendedBooks: this.data.recommendedBooks || [],
                    freeBook: this.book
                }
            });
            this.hasFreeBook = true;
        }

        //restore chat
        this._chatWidgetService.resetPosition();
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        }
        else {
            this.emailSuggestionError = "";
        }
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({ event: "email_typo_correction", params: { old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1] } });
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";
        }
    }

    onStickyInputClicked(event: Event) {
        setTimeout(() => {
            this._utilitiesService.scrollStickyContainerVisible(event.target as HTMLElement);
        }, 500);
    }
}
