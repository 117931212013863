import { ChangeDetectionStrategy, Component, Input, ElementRef } from '@angular/core';
import { Observable, map, shareReplay, tap } from 'rxjs';
import { Book } from 'src/app/models/book.model';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { BooksService } from 'src/app/services/books.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
    selector: 'worldmaker-component-featured-prelaunches',
    templateUrl: './featured-prelaunches.component.html',
    styleUrls: ['./featured-prelaunches.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedPrelaunchesComponent {

    @Input()
    data!: {
        headerText: string;
        showHR: boolean;
        enableAnchor: boolean;
        anchorSlug?: string;
    };

    books$: Observable<Book[]> = this._booksService.findAllPrelaunches().pipe(
        map(res => res.data),
        shareReplay(1),
        tap(books => {
            //hide the component itself if there are no prelaunch books
            if (!books.length) {
                this._elementRef.nativeElement.style.display = "none";
            }
        })
    );

    layoutConfig: ColumnLayoutConfig = {
        mobile: 2,
        sm: 3,
        md: 4,
        lg: 6
    }

    ds$: Observable<ContentGridDataSource<Book>> = this.books$.pipe(
        map(books => {
            return {
                data: books,
                image: b => b.images.cover,
                subtitle: "subtitle",
                title: "title",
                url: b => this._utilitiesService.getBookUrl(b, false),
                badge: {
                    enabled: true,
                    text: "COMING SOON",
                    type: "light"
                }
            }
        })
    )   

    constructor(
        private readonly _booksService: BooksService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _elementRef: ElementRef
    ) {}

}
