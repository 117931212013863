import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookPageComponent } from '../../pages/book-page/book-page/book-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AuthorComponent } from './components/authors/author/author.component';
import { AuthorBioComponent } from './components/authors/author-bio/author-bio.component';
import { BookInfo } from './components/books/book-info/book-info.component';
import { SeriesInfo } from './components/series/series-info/series-info.component';
import { DiscussionModule } from '../discussion/discussion.module';
import { JoinBookCrewModalComponent } from './components/books/join-book-crew-modal/join-book-crew-modal.component';
import { AuthModule } from '../auth/auth.module';
import {RouterModule} from '@angular/router';
import { FloatingBookCrewCtaComponent } from './components/books/floating-book-crew-cta/floating-book-crew-cta.component';
import { ReadOnAmazonButtonComponent } from './components/books/read-on-amazon-button/read-on-amazon-button.component';
import { BookHeader } from './components/books/book-header/book-header.component';
import { ReadablesModule } from '../readables/readables.module';
import { BookHeaderContainer } from './components/books/book-header-container/book-header-container.component';
import { CharacterOverviewsComponent } from './components/books/character-overviews/character-overviews.component';
import { AuthorLandingPageContainerComponent } from './components/authors/author-landing-page/author-landing-page-container/author-landing-page-container.component';
import { AuthorLandingPageComponent } from './components/authors/author-landing-page/author-landing-page/author-landing-page.component';
import { AuthorLandingPageFormComponent } from './components/authors/author-landing-page/author-landing-page-form/author-landing-page-form.component';

const COMPONENTS = [
    JoinBookCrewModalComponent,
    FloatingBookCrewCtaComponent,
    ReadOnAmazonButtonComponent,
    AuthorComponent,
    AuthorBioComponent,
    AuthorLandingPageContainerComponent,
    AuthorLandingPageComponent,
    AuthorLandingPageFormComponent,
    BookInfo,
    SeriesInfo,
    BookHeader,
    BookHeaderContainer,
    CharacterOverviewsComponent
]

@NgModule({
    declarations: [
        ...COMPONENTS
    ],
    imports: [
        CommonModule,
        AuthModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        DiscussionModule,
        RouterModule,
        ReadablesModule
    ],
    exports: [
        ...COMPONENTS
    ]
})
export class BooksModule { }
