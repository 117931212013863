@if (dataSource && dataSource.data && dataSource.data.length) {
    @if (horizontallyScollable && horizontalChildWidth) {
        <div #horizontalCollection class="row horizontal-collection {{isDesktop && arrows ? 'btn-scroll' : ''}}" (wheel)="scrollAxisReverse($event)" #horizontalScroller>
            @if (isDesktop && arrows && horizontalChildWidth && scrollPosition > 0) {
                <div class="scroll-btn-container scroll-btn-container-left position-absolute  "  (click)="scrollLeft()">
                    <button class="arrow-btn arrow-btn-left {{hover ? 'visible' : 'hidden'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                    </button>
                </div>
            }
            <div [style]="'width:' + horizontalChildWidth + 'px;'" *ngFor="let item of dataSource.data">
                <content-card [template]="template"
                              [title]="getTitle(item)"
                              [subtitle]="getSubtitle(item)"
                              [image]="getImage(item)"
                              [badge]="getBadge(item)"
                              [url]="getUrl(item)"
                              [onClick]="getOnClick(item)"
                              [externalLink]="externalLink"
                              [hideSubtitle]="hideSubtitle"
                              [lazy]="getLazy(item)"
                              [bottomBadge]="getBottomBadge(item)">
                </content-card>
            </div>
            @if (isDesktop && arrows && horizontalChildWidth && scrollPosition < maxScrollPosition) {
                <div class="scroll-btn-container scroll-btn-container-right position-absolute" (click)="scrollRight()">
                    <button class="arrow-btn arrow-btn-right {{hover ? 'visible' : 'hidden'}}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path class="fa-secondary" opacity=".4" d=""/><path class="fa-primary" d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                    </button>
                </div>
            }
        </div>
    } @else if (horizontallyScollable) {
        <div class="row horizontal-collection no-responsive" (wheel)="scrollAxisReverse($event)" #horizontalScroller>
            <div *ngFor="let item of dataSource.data">
                <content-card [template]="template"
                              [title]="getTitle(item)"
                              [subtitle]="getSubtitle(item)"
                              [image]="getImage(item)"
                              [badge]="getBadge(item)"
                              [url]="getUrl(item)"
                              [onClick]="getOnClick(item)"
                              [externalLink]="externalLink"
                              [hideSubtitle]="hideSubtitle"
                              [lazy]="getLazy(item)"
                              [bottomBadge]="getBottomBadge(item)">
                </content-card>
            </div>
        </div>
    } @else if (!horizontallyScollable) {
        <div class="row vertical-collection">
            <div class="col-{{12 / columnLayout.mobile}} col-sm-{{12 / columnLayout.sm}} col-md-{{12 / columnLayout.md}} col-lg-{{12 / columnLayout.lg}}" *ngFor="let item of dataSource.data">
                <content-card [template]="template"
                              [title]="getTitle(item)"
                              [subtitle]="getSubtitle(item)"
                              [image]="getImage(item)"
                              [badge]="getBadge(item)"
                              [url]="getUrl(item)"
                              [onClick]="getOnClick(item)"
                              [externalLink]="externalLink"
                              [hideSubtitle]="hideSubtitle"
                              [lazy]="getLazy(item)"
                              [bottomBadge]="getBottomBadge(item)">
                </content-card>
            </div>
        </div>
    }
}
