@if (author && author.landingPageData) {
    <div class="d-flex justify-content-center landing-page-row"
    [backgroundImage]="backgroundImage! | cloudinaryFormat | cloudinaryUrl" 
    [backgroundColor]="backgroundColor"
    [backgroundVideo]="backgroundVideo"
    [style.--page-text-color]="textColor"
    [ngClass]="{'hide-scroll': previewMode}">
        <div id="top"></div>
        <div class="d-flex flex-column landing-page-container c-pt-60 c-mx-16">
            <div class="d-flex flex-column align-items-center c-mb-24 author-header">
                <img *ngIf="author.images.profile" [src]="author.images.profile  | cloudinaryScaleHeight:150 | cloudinaryQuality:100 | cloudinaryFormat | cloudinaryUrl" preloadImage [alt]="author.name" class="c-mb-8 bubble-image">
                <h1 class="text-center c-mb-8">
                    {{author.name}}
                </h1>
                <p class="text-center mb-0" *ngIf="author.landingPageData.tagline">
                    <span class="tagline c-mb-4">
                        {{author.landingPageData.tagline}}
                    </span>
                </p>
            </div>
            <div #featuredBookEl class="d-flex flex-column align-items-center c-mb-48 c-p-16 card featured-book">
                <div class="preview-mode-overlay" *ngIf="previewMode"></div>
                <img *ngIf="readerMagnetMode === 'free_book' && featuredBook!.images.cover" preloadImage [src]="featuredBook!.images.cover | cloudinaryScaleHeight:190 | cloudinaryQuality:100 | cloudinaryFormat | cloudinaryUrl" class="c-mb-16" [alt]="featuredBook!.title" height="190">
                <p [ngClass]="{'simple-form-description': readerMagnetMode === 'simple_form'}">
                    {{readerMagnetMode === "free_book" ? author.landingPageData.readerMagnet.freeBookForm.description : author.landingPageData.readerMagnet.simpleForm.description}}
                </p>
                <author-landing-page-form [previewMode]="previewMode" [book]="featuredBook" [author]="author"></author-landing-page-form>
                <span *ngIf="readerMagnetMode === 'free_book'" class="position-absolute cover-badge">
                    Free
                </span>
            </div>
            <div id="books" class="d-flex flex-column c-mb-32 explore">
                <h2 class="c-mb-16">Explore {{author.name}}</h2>

                <div *ngIf="(featuredBooksDataSource$ | async) as booksDs" class="card d-flex flex-column c-py-16 c-px-4 c-mb-24">
                    <h3 class="c-px-16 c-mb-8">
                        Featured
                    </h3>
                    <content-grid [dataSource]="booksDs" [horizontallyScollable]="true" [horizontalChildWidth]="140" [hideSubtitle]="true" [externalLink]="true" [columnLayout]="columnLayout"></content-grid>
                </div>


                <div *ngIf="(authorSeriesDataSource$ | async) as seriesDs" class="card d-flex flex-column c-py-16 c-px-4 c-mb-16">
                    <h3 class="c-px-16 c-mb-8">
                        Book series
                    </h3>
                    <content-grid [dataSource]="seriesDs" [horizontallyScollable]="true" [horizontalChildWidth]="140" [hideSubtitle]="true" [externalLink]="true" [columnLayout]="columnLayout"></content-grid>
                </div>
                <div class="w-100 text-white text-center">
                    <a [href]="authorUrl" class="more-of" target="__blank" *ngIf="!previewMode">
                        More books of {{author.name}}
                    </a>
                    <span class="more-of" *ngIf="previewMode">
                        More books of {{author.name}}
                    </span>
                </div>
            </div>

            <ng-container *ngIf="genresConfig() as genres">
                <div id="genres" *ngIf="genres.length" class="d-flex flex-column">
                    <h2 class="c-mb-12">Genres</h2>
                    <badge-list [data]="genres" [disableClick]="true" [expandable]="true" [showInitial]="6"></badge-list>
                </div>
            </ng-container>

            <ng-container *ngIf="tropesConfig() as tropes">
                <div id="tropes" *ngIf="tropes.length" class="d-flex flex-column c-mt-24">
                    <h2 class="c-mb-12">Tropes</h2>
                    <badge-list [data]="tropes" [disableClick]="true" [expandable]="true" [showInitial]="6"></badge-list>
                </div>
            </ng-container>

            <div id="reviews" *ngIf="featuredReviews && featuredReviews.length" class="d-flex c-mt-24 {{previewMode ? 'c-pb-32' : 'c-pb-200'}} c-pb-sm-120" [style.--reviews-count]="featuredReviews.length >= 3 ? 3 : featuredReviews.length">
                <div>
                    <h2 class="c-mb-12">Featured reviews</h2>
                    <reviews-list [items]="featuredReviews.slice(0, 3)"
                              [linksEnabled]="previewMode ? false : true"
                              [horizontalMode]="false"
                              [singleColMode]="true"></reviews-list>
                </div>
            </div>
            <div *ngIf="!featuredReviews || !featuredReviews.length" class="{{previewMode ? 'c-pb-32' : 'c-pb-200'}} c-pb-sm-120"></div>
        </div>
        <ng-container *ngIf="!previewMode && (scrolled$ | async)">
            <div 
                 class="sticky-container container-xxl" 
                 [@stickyFooterAnimation]
                >
                <div class="row c-p-20">
                    <div class="col-12 col-sm-7 d-flex align-items-center ps-0">
                        <img *ngIf="featuredBook?.images?.cover" class="c-me-12" [src]="featuredBook!.images!.cover | cloudinaryScaleHeight:48 | cloudinaryQuality:100 | cloudinaryFormat | cloudinaryUrl" height="48"  alt="">
                        <div class="w-100 d-flex flex-column">
                            <h4 class="c-mb-4 text-truncate">
                                {{featuredBook?.title}}
                            </h4>
                            <span *ngIf="featuredBook" class="c-mb-0">
                                {{author.name}}
                            </span>
                        </div>
                    </div>
                    <div *ngIf="readerMagnetMode === 'simple_form'" class="col-12 col-sm-7 d-flex align-items-center px-0">
                        <div class="w-100 d-flex align-items-center align-items-sm-start flex-column">
                            <h4 class="c-mb-4">
                                {{author.landingPageData.readerMagnet.simpleForm.description}}
                            </h4>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 px-0 d-flex justify-content-center justify-content-sm-end align-items-center c-mt-16 c-mt-sm-0">
                        <button class="btn btn-dark btn-round btn-large" (click)="scrollToFeatured()">
                            {{(readerMagnetMode === 'free_book' ? author.landingPageData.readerMagnet.freeBookForm.buttonText : author.landingPageData.readerMagnet.simpleForm.buttonText) || "Join"}}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
}