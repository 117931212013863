import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BooksService } from 'src/app/services/books.service';
import { Book } from 'src/app/models/book.model';
import { firstValueFrom } from 'rxjs';
//need to import this here for the tooltip to work
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from "dayjs";
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
    selector: "worldmaker-component-book-recommendations",
    templateUrl: "./book-recommendations.component.html",
    styleUrl: "./book-recommendations.component.scss"
})
export class BookRecommendations implements OnChanges {
    @Input()
    data!: {
        book?: Book;
        headerText: string;
        featuredNewReleaseMode: boolean;
        featuredGenre: string;
        hideLatestRelease: boolean;
        showHR: boolean;
        placement?: string;
    }

    books!: Book[];

    constructor(
        private readonly _analyticsService: AnalyticsService,
        private readonly _booksService: BooksService,
        private readonly _router: Router,
        private readonly _utilitiesService: UtilitiesService
    ) {}

    get featuredRelease() {
        return this.books[0];
    }

    //try for scottish or regency first
    //then historical
    //and then fallback to any genre that has 2 words (which are usually really specific)
    //and then final fallback to the first genre
    get mainGenre() {
        const scottishOrRegency = this.featuredRelease.genres.find(genre => genre.slug === "scottish-romance" || genre.slug === "regency");
        if (scottishOrRegency) {
            return scottishOrRegency;
        }
        const historical = this.featuredRelease.genres.find(genre => genre.slug === "historical-romance");
        if (historical) {
            return historical;
        }
        return this.featuredRelease.genres.find(genre => genre.name.split(" ").length === 2) || this.featuredRelease.genres[0];
    }

    get mainTrope() {
        return this.featuredRelease.mainTrope || this.featuredRelease.tropes[0];
    }

    get spicyMeterLabel() {
        switch (this.featuredRelease.spicyMeter) {
            case 1:
                return "Sweet"
            case 2:
                return "Sensual";
            case 3:
                return "Sexy";
            case 4:
                return "Steamy";
            case 5:
                return "Spicy";
            default:
                return "";
        }
    }

    getReleaseBadge(book: Book) {
        if (!book.publishedAt) return;
        if (book.isPrelaunched || book.isFree) return;
        if (dayjs(book.publishedAt).isAfter(dayjs().subtract(7, "days"))) {
            return "New Release";
        } else if ((dayjs(book.publishedAt).isAfter(dayjs().subtract(14, "days")))) {
            return "Recent Release";
        }
        return;
    }

    get normalModeBooks() {
        let books = this.books;
        if (this.data.hideLatestRelease) {
            books = this.books.slice(1);
        }
        //on normalMode (usually in book pages) we don't want to show ourselves
        return books.filter(book => {
            return this._router.url !== this._utilitiesService.getBookUrl(book);
        });
    }

    get featuredNewReleaseMode() {
        return this.data.featuredNewReleaseMode && this.books.length > 6;
    }

    async ngOnInit() {
        this.books = await firstValueFrom(this._booksService.getBookRecommendations(this.data.book?.slug));
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (!changes.data.isFirstChange()) {
            this.books = await firstValueFrom(this._booksService.getBookRecommendations(this.data.book?.slug));
        }
    }

    getOnClick(book: Book, to: "amazon" | "book_page") {
        return (event?: Event) => {
            if (event) event.stopPropagation();
            if (to === "amazon") {
                this._analyticsService.track({event: "go_to_amazon", params: {bookOrseries: book, clickSource: "web"}});
            }
            this._analyticsService.track({event: "recommendation_click", params: {
                book: book, 
                destinationType: to,
                placement: this.data.placement,
                recommendationType: this.getReleaseBadge(book) || "Older Release",
                currentUrl: this._router.url
            }});
        };
    }
}