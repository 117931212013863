import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import emailSpellChecker from '@zootools/email-spell-checker';
import { Book } from 'src/app/models/book.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { Author } from 'src/app/models/author.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { PlatformService } from 'src/app/services/platform.service';


@Component({
    selector: 'author-landing-page-form',
    templateUrl: './author-landing-page-form.component.html',
    styleUrl: './author-landing-page-form.component.scss',
    animations: [
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        ),
        trigger("fadeIn", [
            transition(":enter", [
                style({ opacity: 0 }),
                animate("0.5s ease-in", style({ opacity: 1 })),
            ]),
        ]),
    ]
})
export class AuthorLandingPageFormComponent implements OnInit {

    hasFreeBook = false;
    simpleFormThankYou = false;
    ready = false;
    emailSuggestionError = "";
    subscribing = false;

    @Input() previewMode = false;
    @Input() book!: Book | null;
    @Input()
    author: Author | null = null;

    constructor(
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _platformService: PlatformService,
        private readonly _cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (!this._platformService.isBrowser()) return;
        if (this.book && this._anonymousPersistentState.freeBooks.includes(this.book.slug)) {
            this.hasFreeBook = true;
        }
        if (this._anonymousPersistentState.email) {
            if (!this.previewMode) {
                this.emailFormControl.setValue(this._anonymousPersistentState.email);
            }
            if (this.readerMagnetMode === "simple_form" && this._anonymousPersistentState.authorSubscriptions.includes(this.author!.slug)) {
                this.simpleFormThankYou = true;
            }
        }
        this.ready = true;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    async submit() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;
        this._anonymousPersistentState.saveEmail(email);

        if (this.readerMagnetMode === "simple_form") {
            this.subscribing = true;
            await this._analyticsService.identifyAndTrack({ email, traits: { signupReason: { type: "author_landing_page", author: this.author!.slug } } }, {
                event: "simple_form",
                params: { authorSlug: this.author!.slug, type: "simple_form" }
            });
            this._anonymousPersistentState.saveAuthorSubscription(this.author!.slug);
            this.subscribing = false;
            this.simpleFormThankYou = true;
            this._cdr.markForCheck();
        } 
        else if (this.readerMagnetMode === "free_book" && this.book) {
            const acValue = `${this.book.mainAuthorSlug}--${this.book.slug}`;
            this.subscribing = true;
            await this._analyticsService.identifyAndTrack({ email, traits: { signupReason: { type: "author_landing_page", author: this.author!.slug } } }, {
                event: "get_free_book",
                params: { book: this.book, acValue, type: "free_book", placement: "author_landing_page" }
            });
    
            this._analyticsService.trackClientOnly({ event: "get_free_book_c", params: { book: this.book, acValue, type: "free_book", placement: "author_landing_page" } });
    
            this._anonymousPersistentState.saveFreeBook(this.book.slug);
            this._anonymousPersistentState.saveAuthorSubscription(this.author!.slug);
            

            this.goToLibrary();
        }
    }

    goToLibrary() {
        if (!this.book || !this._anonymousPersistentState.email) return;
        // window.open(`${environment.baseUrl}/library/shelves/free?email=${this._anonymousPersistentState.email}&r=${this.book.slug}`);
        location.href = `${environment.baseUrl}/library/shelves/free?email=${this._anonymousPersistentState.email}&r=${this.book.slug}`;
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        }
        else {
            this.emailSuggestionError = "";
        }
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({ event: "email_typo_correction", params: { old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1] } });
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";
        }
    }

    onStickyInputClicked(event: Event) {
        setTimeout(() => {
            this._utilitiesService.scrollStickyContainerVisible(event.target as HTMLElement);
        }, 500);
    }

    get buttonText() {
        if (this.readerMagnetMode === "free_book" && this.book) {
            return this.author!.landingPageData.readerMagnet.freeBookForm.buttonText;
        } else {
            return this.author!.landingPageData.readerMagnet.simpleForm.buttonText;
        }
    }

    get readerMagnetMode() {
        if (this.author?.landingPageData.readerMagnet.mode === "free_book" && this.book) {
            return "free_book";
        } else {
            return "simple_form";
        }
    }
}
