import { Component, Input, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import emailSpellChecker from '@zootools/email-spell-checker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Book } from 'src/app/models/book.model';
import { BookSeries } from 'src/app/models/book-series.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, filter, firstValueFrom } from 'rxjs';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { CanonicalService } from 'src/app/services/canonical.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'worldmaker-component-prelaunch-cover-header-2',
    templateUrl: './prelaunch-cover-header-2.component.html',
    styleUrls: ['./prelaunch-cover-header-2.component.scss'],
    animations: [
        trigger(
          'stickyFooterAnimation', 
          [
            transition(
              ':enter', 
              [
                style({ "max-height": 0 }),
                animate('1s ease-out', style({ "max-height": "250px" }))
              ]
            ),
            transition(
              ':leave', 
              [
                style({ "max-height": "250px" }),
                animate('1s ease-in', style({ "max-height": 0 }))
              ]
            )
          ]
        ),
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        )
    ]
})
export class PrelaunchCoverHeader2 implements OnInit {
    @Input() data!: {
        book: Book;
        joinCrewImage?: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        };
        ctaText: string;
        headerText: string;
        showHeader: boolean;
        alertText?: string;
        enableStickyFooter?: boolean;
        showRating: boolean;
        showSpicyMeter: boolean;
        altCoverIndex?: string;
    };
    book!: Book;
    bookSeries?: BookSeries;

    loading = false;
    scrolled = false;
    joinedPrelaunch = false;
    emailSuggestionError = "";
    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    followsAuthor = false;
    joinedCrew = false;
    requestLoginCb = async () => {
        this._modalService.dismissAll();
        await this._loginForCrew();
    };
    afterJoinCb = () => {
        //also follow when they join the crew
        if (!this.followsAuthor) {
            this._followService.followAuthor({source: "after_join_crew_auto"});
        }
        //scroll past the header
        const header = document.querySelector(".cover-container");
        if (header) {
            const headerHeight = parseInt(getComputedStyle(header).height.replace("px", ""));
            if (window.scrollY < headerHeight) {
                window.scroll(0, headerHeight - window.scrollY);
            }
        }
        this._modalService.dismissAll();
    }

    @ViewChild('joinModal') joinModal!: TemplateRef<any>;

    followRequestInProgress$ = this._route.queryParams.pipe(
        filter(params => params["action"] === "follow_author" && params["author"] === this.mainAuthor.slug),
        filter(() => this._platformService.isBrowser())
    );

    authChecked$ = this._authService.browserAuthCheckPassed$;

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _followService: FollowService,
        private readonly _modalService: NgbModal,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _canonicalService: CanonicalService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    async ngOnInit() {
        this.book = this.data.book;
        if (!this._router.url.includes("/pages")) {
            this._canonicalService.set(`${environment.baseUrl}${this._utilitiesService.getBookUrl(this.book)}`);
        }
        this._restoreState();
        let email = this._anonymousPersistentState.email;
        email = (await firstValueFrom(this._route.queryParamMap)).get("email") || email;
        if (this._authService.user) {
            email = this._authService.user.email;
        }
        if (email) {
            this.emailFormControl.setValue(email);
        }
        this._followService.followsAuthor$.subscribe(val => {
            this.followsAuthor = val;
        });
        if (this.mainAuthor.crew) {
            this._followService.joinedCrew$.subscribe(val => {
                this.joinedCrew = val;
            });
        }
        await this._followService.init(
            this.book,
            this.mainAuthor,
            null
        );
        this._analyticsService.trackPageView(undefined, "Book Page", {
            book: this.book.slug,
            author: this.book.mainAuthorSlug,
            mode: this.book.isPrelaunched ? "prelaunch" : "live",
            publisher: this.book.publisher || undefined
        });

        combineLatest([
            this._authService.isLoggedIn$,
            this._authService.onboardingCompleted$,
            this.followRequestInProgress$
        ]).pipe(
            filter(([isLoggedIn, onboardingCompleted, followInProgress]) => isLoggedIn && onboardingCompleted && Boolean(followInProgress)),
        ).subscribe(() => {
            this.followAuthor();
        })
    }

    @HostListener("window:scroll")
    async onWindowScroll() {
        if (!this.data.enableStickyFooter) return;
        const headerHeight = parseInt(getComputedStyle(document.querySelector(".book-header-container")!).height.replace("px", ""));
        if (window.scrollY >= headerHeight) {
            this.scrolled = true;
            const sticky = document.querySelector(".sticky-container");
            if (sticky) {
                setTimeout(() => {
                    const stickyHeight = getComputedStyle(sticky).height;
                    const stickyHeightNumber = parseInt(stickyHeight.replace("px", ""));
                    this._chatWidgetService.slideUp(stickyHeightNumber + 10);
                }, 1200);
            }
        } else {
            this.scrolled = false;
            setTimeout(() => {
                this._chatWidgetService.resetPosition();
            }, 500);
        }
    }

    get mainAuthor() {
        const main = this.book.authors.find(
            (author) => author.slug === this.book.mainAuthorSlug
        );
        if (main) {
            return main;
        } else {
            return this.book.authors[0];
        }
    }

    get isMobile() {
        return this._platformService.isMobile;
    }

    get coverSrc(): string {
        try {
            let src;
            if (this.data.altCoverIndex !== undefined && Number(this.data.altCoverIndex) >= 0 && this.book.images.altCovers?.length) {
                src = this.book.images.altCovers[Number(this.data.altCoverIndex)];
            }
            else {
                src = this.book.images.cover;
            }
            //only overwrite it on normal book pages, not landing pages
            if (!this._router.url.match("/pages/")) {
                this._platformService.metaTags.setOpenGraphImage(src);
            }
            return src;
        }
        catch(e) {
            console.warn("Error in book cover source", e);
            return this.book.images.cover;
        }
    }

    get crewFormSettings() {
        return {
            trigger: "prelaunch",
            msg: this.data.alertText || "",
            joinCrewImage: this.data.joinCrewImage
        } as const;
    }

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        return {
            joinedFrom: "book_page",
            book: this.book.slug,
            mode: this.book.isPrelaunched ? "prelaunch" : "live"
        };
    }

    async readItFirst() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;
        this._anonymousPersistentState.saveEmail(email);
        this._analyticsService.identifyAndTrack({email, traits: {signupReason: {type: "prelaunch_flow", book: this.book.slug}}}, {
            event: "join_prelaunch",
            params: {book: this.book}
        });
        this.joinedPrelaunch = true;
        this._saveState(email);

        await this.joinCrew();
    }

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    onStickyInputClicked(event: Event) {
        // Fix for Safari
        setTimeout(() => {
            this._utilitiesService.scrollStickyContainerVisible(event.target as HTMLElement);
        }, 500);
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";

        }
    }

    async joinCrew() {
        if (!this.joinedCrew && this.mainAuthor.crew) {
            this._chatWidgetService.hide();
            const that = this;
            await firstValueFrom(
                this._modalService.open(this.joinModal, {
                    centered: true,
                    fullscreen: await firstValueFrom(this._platformService.isMobile),
                    beforeDismiss() {
                        that._chatWidgetService.show();
                        return true;
                    },
                }).shown
            );
        }
    }

    async followAuthor() {
        await this._loginForFollow();
        await this._followService.followAuthor({source: "book_page", book: this.book.slug});

        if (!this.joinedCrew && this.mainAuthor.crew) {
            this._chatWidgetService.hide();
            const that = this;
            //TODO make modalService.close() to wait for modal hidden event
            await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
            await firstValueFrom(
                this._modalService.open(this.joinModal, {
                    centered: true,
                    fullscreen: await firstValueFrom(this._platformService.isMobile),
                    beforeDismiss() {
                        that._chatWidgetService.show();
                        return true;
                    },
                }).shown
            );
        }
    }

    async unfollowAuthor() {
        await this._followService.unfollowAuthor();
    }

    private async _loginForFollow() {
        if (!this._authService.user) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "book_page_follow_author",
                        author: this.book.mainAuthorSlug,
                        book: this.book.slug
                    }
                }
            });
            await this._authService.login({
                sendStageTitle: "Sign in or sign up",
                sendStageDescription: `Sign-in or register for free to follow ${this.mainAuthor.name}`,
                skipOnboarding: false,
                signupReason: {type: "follow_author", author: this.mainAuthor.slug},
                action: "follow_author",
                actionParams: {
                    author: this.mainAuthor.slug
                }
            });
        }
    }

    private async _loginForCrew() {
        if (!this._authService.user) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "book_page_follow_author",
                        author: this.book.mainAuthorSlug,
                        book: this.book.slug
                    }
                }
            });
            await this._authService.login({
                sendStageTitle: "Sign in or sign up",
                sendStageDescription: `Sign-in or register for free to join ${this.mainAuthor.crew?.crewName}`,
                skipOnboarding: false,
                signupReason: {type: "join_crew", author: this.mainAuthor.slug},
                action: "follow_author", //just doing follow here as well, it should trigger the join crew afterwards
                actionParams: {
                    author: this.mainAuthor.slug
                }
            });
        }
    }

    private _restoreState() {
        if (!this._platformService.isBrowser()) return;
        //not sure why, but not doing the setTimeout messes with ssr stabilization
        setTimeout(() => {
            const route = this._router.url.split("?")[0];
            if (this._anonymousPersistentState.prelaunches[route]) {
                this.joinedPrelaunch = this._anonymousPersistentState.prelaunches[route].joinedPrelaunch;
            }
        }, 0);
    }

    private _saveState(email: string) {
        if (!this._platformService.isBrowser()) return;
        this._anonymousPersistentState.saveEmail(email);
        this._anonymousPersistentState.savePrelaunch(this._router.url.split("?")[0], {
            joinedPrelaunch: this.joinedPrelaunch
        });
    }
}
