<div class="d-block d-lg-none mobile-trigger">
    <a class="" (click)="openMobileSearch()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.4434 16.4434L20.9997 20.9997" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>Search</span>
    </a>
</div>

<div class="d-none d-lg-block dropdown-center" #desktopSearchContainer>
    <div class="desktop-search-toggle">
        <form [formGroup]="searchForm" (ngSubmit)="desktopSearch()">
            <div class="input-group search-input-container fixed-width">
                <span class="input-group-text embedded-search-icon" [ngClass]="{'focused': searchInputActive}">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.4434 16.4434L20.9997 20.9997" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                <input
                    name="q"
                    type="text"
                    formControlName="q"
                    class="form-control dark-input rounded-input search-input-with-icon"
                    [placeholder]="searchInputActive ? 'What do you want to read?' : 'Search'"
                    (focus)="initDesktopSearch()"
                    (focusout)="searchInputActive = false"
                    [ngClass]="{'focused': searchInputActive}"
                >
                <span
                    class="input-group-text search-bar-control clear"
                    *ngIf="(queryControl.value || results) && desktopSearchActive" (click)="clear()"
                    [ngClass]="{'focused': searchInputActive}"><span [@searchControlTransition]>Clear</span></span>
                <span class="input-group-text search-bar-control close"
                    (click)="stopAndClearSearch()"
                    [ngClass]="{'focused': searchInputActive}">
                    <span *ngIf="desktopSearchActive" [@searchControlTransition]>
                        <feather-icon icon="x" width="20" height="20"></feather-icon>
                    </span>
                </span>
            </div>
        </form>
    </div>
    <div class="dropdown-menu desktop-search-dropdown">
        <ng-container [ngTemplateOutlet]="searchSections"></ng-container>
    </div>
</div>

<div class="desktop-backdrop" *ngIf="desktopSearchActive" (click)="stopSearch()" [@backdropTransition]></div>

<ng-template #mobileModal let-modal>
    <div class="modal-body c-px-20">
        <div class="mobile-header-container">
            <form [formGroup]="searchForm" (ngSubmit)="mobileSearch()">
                <div class="input-group search-input-container">
                    <span class="input-group-text embedded-search-icon" [ngClass]="{'focused': searchInputActive}">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.4434 16.4434L20.9997 20.9997" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <input
                        name="q"
                        type="text"
                        formControlName="q"
                        class="form-control dark-input rounded-input search-input-with-icon"
                        [placeholder]="searchInputActive ? 'What do you want to read?' : 'Search'"
                        (focus)="searchInputActive = true"
                        (focusout)="searchInputActive = false"
                        [ngClass]="{'focused': searchInputActive}"
                    >
                    <span
                        class="input-group-text search-bar-control clear"
                        *ngIf="queryControl.value || results" (click)="clear()"
                        [ngClass]="{'focused': searchInputActive}"><span [@searchControlTransition]>Clear</span></span>
                    <span class="input-group-text search-bar-control close"
                        (click)="stopAndClearSearch()"
                        [ngClass]="{'focused': searchInputActive}">
                        <span>
                            <feather-icon icon="x" width="20" height="20"></feather-icon>
                        </span>
                    </span>
                </div>
            </form>
        </div>
        <div class="c-pt-30">
            <ng-container [ngTemplateOutlet]="searchSections"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #searchSections>
    <div class="categories" *ngIf="categories && categories.length">
        <span *ngFor="let category of categories" (click)="activeCategory = category" [ngClass]="{'active': activeCategory === category}">{{category}}</span>
    </div>
    <div *ngIf="noResultsQuery" class="not-found d-flex flex-column justify-content-center align-items-center">
        <div>
            <feather-icon icon="search" width="24" height="24"></feather-icon>
        </div>
        <h4 class="c-mt-24 c-mb-12">No search results</h4>
        <p class="text-muted">There are no search results for '{{noResultsQuery}}'</p>
    </div>
    <ng-container *ngIf="!results && defaultState">
        <div class="section">
            <h6 class="section-label">Trending Books</h6>
            <content-grid [dataSource]="defaultState" [columnLayout]="layoutConfig" [lazy]="true"></content-grid>
        </div>
    </ng-container>
    <div *ngIf="results">
        <div class="section" *ngIf="bookResults && activeCategory === 'Books'">
            <h6 class="section-label">Books</h6>
            <content-grid [dataSource]="bookResults" [columnLayout]="layoutConfig"></content-grid>
        </div>
        <div class="section" *ngIf="seriesResults && activeCategory === 'Series'">
            <h6 class="section-label">Series</h6>
            <content-grid [dataSource]="seriesResults" [columnLayout]="layoutConfig"></content-grid>
        </div>
        <div class="section" *ngIf="authorResults && activeCategory === 'Authors'">
            <h6 class="section-label">Authors</h6>
            <content-grid [dataSource]="authorResults" [columnLayout]="layoutConfig" [hideSubtitle]="true" template="bubbles"></content-grid>
        </div>
        <div class="section" *ngIf="genreResults && activeCategory === 'Genres'">
            <h6 class="section-label">Genres</h6>
            <badge-list [data]="genreResults" [expandable]="true" [showInitial]="10"></badge-list>
        </div>
        <div class="section" *ngIf="tropeResults && activeCategory === 'Tropes'">
            <h6 class="section-label">Tropes</h6>
            <badge-list [data]="tropeResults" [expandable]="true" [showInitial]="10"></badge-list>
        </div>
        <div class="section" *ngIf="worldResults && activeCategory === 'Worlds'">
            <h6 class="section-label">Worlds</h6>
            <div class="row vertical-collection">
                <div class="col-12" *ngFor="let w of worldResults">
                    <title-card [title]="w.title" [url]="'/worlds/' + w.slug" [src]="w.banner || ''" [onClick]="w.onClick"></title-card>
                </div>
            </div>
        </div>
    </div>
</ng-template>