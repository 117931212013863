import { ChangeDetectionStrategy, Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ContentCardComponent } from '../content-card/content-card.component';
import { get } from "lodash-es";
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { PillType } from 'src/app/models/badge-config.model';
import { PlatformService } from 'src/app/services/platform.service';


export interface ContentGridDataSourceBadgeConfig<T> {
    enabled: boolean | ((d: T) => boolean);
    text: string | ((d: T) => string);
    type: PillType | ((d: T) => PillType);
};

export interface ContentGridDataSource<T extends {[key: string]: any}> {
    data: T[];
    title: string | ((d: T) => string);
    subtitle: string | ((d: T) => string);
    image: string | ((d: T) => string);
    lazy?: boolean | ((d: T) => boolean); // Sets loading=lazy to <img> when true. Overrrides ContentGrid setting
    url: string | string[] | ((d: T) => string | string[]);
    badge?: ContentGridDataSourceBadgeConfig<T>;
    onClick?: (d: T) => () => void;
    bottomBadge?: string | ((d: T) => string);
}

export type ContentGridDataSourceKey = "title" | "subtitle" | "image" | "url" | "badge" | "onClick" | "bottomBadge";

@Component({
  selector: 'content-grid',
  templateUrl: './content-grid.component.html',
  styleUrls: ['./content-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentGridComponent<T extends {[key: string]: any}> implements AfterViewInit {
    
    @Input()
    dataSource: ContentGridDataSource<T> | null = null;

    @Input()
    hideSubtitle: boolean = false;

    @Input()
    externalLink: boolean = false;

    @Input()
    columnLayout: ColumnLayoutConfig = {
        mobile: 1,
        sm: 2,
        md: 3,
        lg: 4
    };

    @Input()
    template: "default" | "bubbles" = "default";

    @Input()
    horizontallyScollable = false;

    @Input()
    horizontalChildWidth!: number;

    @Input()
    lazy: boolean = false;

    @Input()
    arrows: boolean = false;

    @Input()
    singleScrollMode: boolean = false; // If set to true, will only scroll one item at a time

    @ViewChild("horizontalCollection")
    horizontalCollection!: ElementRef<HTMLDivElement>;

    scrollPosition: number = 0;
    maxScrollPosition: number = 0;
    private _hover: boolean = false;
    get hover() {
        return this._hover;
    }

    set hover(val: boolean) {
        this._hover = val;
        this._cd.detectChanges();
    }

    constructor(
        private platformService: PlatformService,
        private _cd: ChangeDetectorRef,
        private _el: ElementRef
    ) {

    }

    ngAfterViewInit(): void {
        this.maxScrollPosition = (this.horizontalChildWidth + 15) * (this.dataSource!.data.length - 3);
        if (this.maxScrollPosition < 0) {
            this.maxScrollPosition = 0;
        }
        if (this.platformService.isBrowser()) {
            this._el.nativeElement.parentElement.addEventListener("mouseenter", () => {
                this.hover = true;
            });
            this._el.nativeElement.parentElement.addEventListener("mouseleave", () => {
                this.hover = false;
            })
        }
        this._cd.detectChanges();
    }

    get isDesktop() {
        if (this.platformService.isServer()) {
            return false;
        }
        return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }


    @ViewChild("horizontalScroller") horizontalScroller!: ElementRef<HTMLDivElement>;

    private getPropertyByConfigKey<K>(item: T, key: ContentGridDataSourceKey): any {
        if (this.dataSource && this.dataSource[key] && typeof this.dataSource[key] === 'function') {
            return (this.dataSource[key] as Function)(item);
        }
        else {
            return get(item, key, "");
        }
    }

    getTitle(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "title");
        }
        return "";
    }

    getSubtitle(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "subtitle");
        }
        return "";
    }

    getImage(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "image");
        }
        return "";
    }

    getUrl(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "url");
        }
        return "";
    }

    getBadge(item: T): ContentGridDataSourceBadgeConfig<T> {
        let badgeConfig: ContentGridDataSourceBadgeConfig<T> | undefined = this.dataSource?.badge;
        if (!badgeConfig) {
            return {enabled: false, text: "", type: "primary"};
        }
        let config = {
            ...badgeConfig
        }
        if (typeof badgeConfig.enabled === 'function') {
            config.enabled = badgeConfig.enabled(item);
        }
        if (typeof badgeConfig.text === 'function') {
            config.text = badgeConfig.text(item);
        }
        if (typeof badgeConfig.type === 'function') {
            config.type = badgeConfig.type(item);
        }
        return config;
    }
    
    getLazy(item: T): boolean {
        if (this.dataSource) {
            if (this.dataSource.lazy && typeof this.dataSource.lazy === 'function') {
                return this.dataSource.lazy(item);
            }
            return this.dataSource.lazy || this.lazy;
        }
        return this.lazy;
    }

    getOnClick(item: T) {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "onClick");
        }
        return undefined;
    }

    getBottomBadge(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "bottomBadge");
        }
        return "";
    }

    scrollAxisReverse(event: WheelEvent) {
        // event.preventDefault();
        // Reverse scroll direction and adjust the scrollLeft property
        // this.horizontalScroller.nativeElement.scrollLeft += event.deltaY;
    }

    private getScrollStep(dir: "left" | "right" = "right") {
        const itemSize = this.horizontalChildWidth + 15; // item width + 15px flex gap
        if (this.singleScrollMode) {
            return itemSize;
        }
        const scrolledItems = Math.floor(this.scrollPosition / itemSize);

        if (dir == "right") {
            const remaining = this.dataSource!.data.length - scrolledItems;
            if (remaining > 6) {
                return itemSize * 3;
            }
            else {
                return itemSize;
            }
        }
        else {
            if (scrolledItems >= 3) {
                return itemSize * 3;
            }
            else {
                return itemSize;
            }
        }

    }

    scrollRight() {
        let step = this.getScrollStep();
        this.scrollPosition += step;
        console.log(`scrolling right by ${step} to ${this.scrollPosition} max: ${this.maxScrollPosition}`);
        if (this.scrollPosition + (this.horizontalChildWidth + 15) >= this.maxScrollPosition) {
            step = this.horizontalCollection.nativeElement.scrollWidth;
            this.scrollPosition = this.maxScrollPosition;
        }
        this.horizontalCollection.nativeElement.scrollBy({left: step, behavior: 'smooth'});
    }


    scrollLeft() {
        let step = this.getScrollStep("left");
        if (this.scrollPosition > 0) {
            this.scrollPosition -= step;
            if (this.scrollPosition <= (this.horizontalChildWidth + 15)) {
                step += this.scrollPosition;
                this.scrollPosition = 0;
            }
            console.log(`scrolling left by ${step} to ${this.scrollPosition} max: ${this.maxScrollPosition}`);
            this.horizontalCollection.nativeElement.scrollBy({left: -step, behavior: 'smooth'});
        }
    }
}
