import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Author } from '../models/author.model';
import { Genre } from '../models/genre.model';
import { Trope } from '../models/trope.model';
import { environment } from 'src/environments/environment';
import { BookReview } from '../models/book.model';

interface AuthorsByGenreResponse {
    authors: Author[];
    genre: Genre;
}

@Injectable({
    providedIn: 'root'
})
export class AuthorsService {
    private apiUrl = `${environment.baseUrl}/api/authors`;

    constructor(private readonly http: HttpClient) { }

    findById(id: number): Observable<Author>  {
        const url = `${this.apiUrl}/id/${id}`;

        return this.http.get<Author>(url);
    }

    findByName(name: string): Observable<Author | null> {
        const url = `${this.apiUrl}/name`;
        return this.http.get<Author | null>(url, { params: { name } });
    }

    findOneBySlug(slug: string): Observable<Author | undefined> {
        const url = `${this.apiUrl}/slug/${slug}`;
        return this.http.get<Author | undefined>(url);
    }

    findOneByPageSlug(slug: string): Observable<Author | undefined> {
        const url = `${this.apiUrl}/page-slug/${slug}`;
        return this.http.get<Author | undefined>(url);
    }

    findByGenre(slug: string): Observable<AuthorsByGenreResponse> {
        const url = `${this.apiUrl}/genre/${slug}`;
        return this.http.get<AuthorsByGenreResponse>(url);
    }

    findAll(): Observable<Author[]> {
        return this.http.get<Author[]>(this.apiUrl);
    }

    getAuthorGenres(id: number): Observable<Genre[]> {
        return this.http.get<Genre[]>(`${this.apiUrl}/${id}/genres`);
    }

    getAuthorTropes(id: number): Observable<Trope[]> {
        return this.http.get<Trope[]>(`${this.apiUrl}/${id}/tropes`);
    }

    findByIds(ids: number[]): Observable<Author[]> {
        return this.http.post<Author[]>(`${this.apiUrl}/query`, { ids });
    }

    getFeaturedReviews(authorId: number): Observable<BookReview[]> {
        return this.http.get<BookReview[]>(`${this.apiUrl}/${authorId}/featured-reviews`);
    }
}
