import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, of, shareReplay, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Book } from '../models/book.model';
import { Author } from '../models/author.model';
import { Genre } from '../models/genre.model';
import { Trope } from '../models/trope.model';

type RecommendationType = "featured_book" | "new_and_hot" | "coming_soon" | "free_books" | "category" | "featured_authors" | "popular_themes";

type Manifest = {
    id: string,
    version: number,
    type: RecommendationType
}[];

type RecommendationData<T extends RecommendationType> =
    T extends "featured_book" ? { book: Book } :
    T extends "new_and_hot" | "coming_soon" | "free_books" ? { books: Book[] } :
    T extends "category" ? { title: string, books: Book[] } :
    T extends "featured_authors" ? { authors: (Author & {landingPageLink: string})[] } :
    T extends "popular_themes" ? {
        entries: ({
            type: "genre",
            genre: Genre
        } | {
            type: "trope",
            trope: Trope
        })[]
    } : never;

type Recommendation<T extends RecommendationType> = {
    id: string,
    version: number,
    type: T,
    data: RecommendationData<T>
};

@Injectable({
    providedIn: "root",
})
export class RecommendationsService {
    constructor(private http: HttpClient) { }

    private apiUrl = `${environment.baseUrl}/api/recommendations`;

    private manifest = this.http.get<Manifest>(`${this.apiUrl}/manifest`).pipe(
        shareReplay(1),
        catchError(() => of([]))
    );

    private cache: { [recommendationId: string]: Recommendation<any> } = {};

    private sessionTracker: { [session: string]: string[] } = {};

    getRecommendation<T extends RecommendationType>(session: string, type: T): Observable<Recommendation<T>> {
        return this.getRecommendations(session, [type]).pipe(take(1), map((recs) => recs[0]));
    }

    getRecommendations<T extends RecommendationType>(session: string, types: T[]): Observable<Recommendation<T>[]> {
        return this.manifest.pipe(
            switchMap(manifest => {
                if (!this.sessionTracker[session]) {
                    this.sessionTracker[session] = [];
                }

                const selectedRecommendations: { type: RecommendationType, id: string }[] = [];

                // For each requested type, find one recommendation
                for (const type of types) {
                    // Find all recommendations of this type
                    const typeRecommendations = manifest.filter(item =>
                        item.type === type
                    );

                    if (typeRecommendations.length === 0) continue;

                    // Find recommendations of this type that haven't been used in this session
                    const unusedTypeRecommendations = typeRecommendations.filter(
                        item => !this.sessionTracker[session].includes(item.id)
                    );

                    // Select one recommendation of this type
                    // If we have unused ones, pick the first unused one
                    // Otherwise, pick the first one of this type (reset)
                    const selectedRecommendation = unusedTypeRecommendations.length > 0
                        ? unusedTypeRecommendations[0]
                        : typeRecommendations[0];

                    // Add to our selection and track in the session
                    selectedRecommendations.push({
                        type: selectedRecommendation.type,
                        id: selectedRecommendation.id
                    });

                    // track it
                    this.sessionTracker[session].push(selectedRecommendation.id);
                }

                const idsToFetch = selectedRecommendations.map(item => item.id);

                if (idsToFetch.length === 0) {
                    return of([]);
                }

                // Check cache
                const cachedIds = idsToFetch.filter(id => this.cache[id] !== undefined);
                const uncachedIds = idsToFetch.filter(id => this.cache[id] === undefined);

                // all cached
                if (uncachedIds.length === 0) {
                    return of(cachedIds.map(id => this.cache[id]));
                }

                // Sort the uuids before fetching to keep urls cachable
                const sortedUncachedIds = [...uncachedIds].sort();

                return this.http.get<Recommendation<T>[]>(`${this.apiUrl}/r/${sortedUncachedIds.join(',')}`).pipe(
                    map(newRecommendations => {
                        for (const rec of newRecommendations) {
                            this.cache[rec.id] = rec;
                        }
                        return idsToFetch.map(id => this.cache[id]);
                    }),
                    catchError(() => {
                        // just return cached
                        return of(cachedIds.map(id => this.cache[id]));
                    })
                );
            })
        );
    }

    //do this on component destroy to start the cycle from the beginning
    clearSession(session: string) {
        this.sessionTracker[session] = [];
    }
}
