@if (book) {
    <div class="row">
        <div class="col-12 col-lg-6">
            <div
                class="book-cover-container d-flex flex-column justify-content-center align-items-center pointy"
                [routerLink]="book | bookUrl"
                (click)="handleClick('book_page')"
                style="--cover-color: {{book.images.cover | cloudinaryPublicId | cloudinaryPrimaryColor | async}}">
                <div *ngIf="releaseBadge" class="cover-badge-container">
                    <span class="cover-badge">{{releaseBadge}}</span>
                </div>
                <img preloadImage
                    class="book-cover c-my-16 c-mb-lg-40 c-mt-lg-24"
                    [ngClass]="{'no-badge': !releaseBadge}"
                    [src]="book.images.cover | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleWidth:220 | cloudinaryUrl"
                    [alt]="book.title"
                    loading="eager"
                    fetchPriority="high">
            </div>
        </div>
        <div class="col-12 col-lg-6 c-pt-24 c-pt-lg-60 c-ps-0 c-ps-lg-60">
            <h1 class="book-title c-mb-8">{{book.title}}</h1>
            <span class="author-badge c-mb-8 c-mb-lg-16">
                <img
                    [src]="mainAuthor.images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                    alt="Author picture"
                    class="circular-pfp"
                />
                <span>{{ book.mainAuthorName }}</span>
            </span>
            <span class="labels c-mb-8 c-mb-lg-16">
                <span *ngIf="mainGenre">{{mainGenre.name}}</span>
                <ng-container *ngIf="book.mainTrope">
                    <ng-container *ngIf="mainGenre">
                        <svg width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2" cy="2.5" r="2" fill="#D9D9D9"/>
                        </svg>
                    </ng-container>
                    <span>{{book.mainTrope.name}}</span>
                </ng-container>
                <ng-container *ngIf="spicyMeterLabel">
                    <ng-container *ngIf="book.mainTrope || mainGenre">
                        <svg width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2" cy="2.5" r="2" fill="#D9D9D9"/>
                        </svg>
                    </ng-container>
                    <span>{{spicyMeterLabel}}</span>
                </ng-container>
            </span>
            <span class="book-header" *ngIf="book.subtitle">{{book.subtitle}}</span>
            <div class="c-pt-24 c-pt-lg-40">
                <a [href]="book | amazonLink" class="btn btn-primary btn-round btn-with-icon btn-large c-mb-16 c-mb-md-0 c-me-0 c-me-md-24" target="_blank" rel="noopener noreferrer" (click)="handleClick('amazon')">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="amazon" fill="#121212" viewBox="0 0 448 512">
                        <path
                            d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                    </svg>
                    Read now
                </a>
                <button class="btn btn-round btn-secondary btn-large" (click)="handleClick('book_page')" [routerLink]="book | bookUrl">
                    More info
                </button>
            </div>
        </div>
    </div>
    <hr class="mb-0 c-mt-40 d-none d-md-block full-width-hr" *ngIf="showHR">
}