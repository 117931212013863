import { Injectable } from "@angular/core";
import { PlatformService } from "./platform.service";
import { UtilitiesService } from "./utilities.service";
import { SignupReason } from "./analytics/events/signupReason";
//@ts-ignore
import {v4 as uuid} from "uuid";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AnonymousPersistentState {
    constructor(
        private readonly _platformService: PlatformService,
        private readonly _utilitiesService: UtilitiesService
    ) {}

    email$ = new BehaviorSubject<string | null>(null);

    private _state: {
        anonId?: string;
        email?: string;
        name?: string;
        tiktok?: string;
        prelaunches: {
            [bookRoute: string]: {
                joinedPrelaunch: boolean;
            }
        },
        campaigns: {
            [route: string]: {
                [key: string]: any
            }
        },
        freeBooks: string[],
        bonusContent: {
            [bookSlug: string]: {
                finishedBonusScene: boolean;
                finishedCharacterOverviews: boolean;
            }
        },
        signupReason?: SignupReason,
        pendingComment?: {discussion: string, content: string, threadId?: string, metadata?: {[key: string]: any}},
        utms?: {campaign?: string, term?: string, content?: string, medium?: string, source?: string},
        reading: {
            [readableSlug: string]: {
                bookmark: number,
                percent: number,
                milestones: number[],
                finished: boolean,
                downloaded: boolean
            }
        },
        altMetadata?: {
            [key: string]: any
        },
        authorSubscriptions: string[]
    } = {prelaunches: {}, freeBooks: [], campaigns: {}, reading: {}, altMetadata: {}, bonusContent: {}, authorSubscriptions: []};

    private _initialized = false;

    async clone(asBase64 = false) {
        let c: any = JSON.parse(JSON.stringify(this._state));
        if (asBase64) {
            c = await this._utilitiesService.objectToBase64(c);
        }
        return c;
    }

    get state() {
        if (!this._initialized) {
            this._restore();
        }
        return this._state;
    }

    get anonId() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.anonId) {
            this._state.anonId = uuid();
            this._persist();
        }
        return this._state.anonId as string;
    }

    get email() {
        if (!this._initialized) {
            this._restore();
        }
        return this._state.email;
    }

    get name() {
        if (!this._initialized) {
            this._restore();
        }
        return this._state.name;
    }

    get tiktok() {
        if (!this._initialized) {
            this._restore();
        }
        return this._state.tiktok;
    }

    get freeBooks() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.freeBooks) {
            this._state.freeBooks = []
            this._persist();
        }
        return this._state.freeBooks;
    }

    get bonusContent() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.bonusContent) {
            this._state.bonusContent = {};
            this._persist();
        }
        return this._state.bonusContent;
    }

    get altMetadata() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.altMetadata) {
            this._state.altMetadata = {}
            this._persist();
        }
        return this._state.altMetadata;
    }

    get prelaunches() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.prelaunches) {
            this._state.prelaunches = {};
            this._persist();
        }
        return this._state.prelaunches;
    }

    get campaigns() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.campaigns) {
            this._state.campaigns = {};
            this._persist();
        }
        return this._state.campaigns;
    }

    get signupReason() {
        if (!this._initialized) {
            this._restore();
        }
        return this._state.signupReason;
    }

    get pendingComment() {
        if (!this._initialized) {
            this._restore();
        }
        return this._state.pendingComment;
    }

    get utms() {
        if (!this._initialized) {
            this._restore();
        }
        return this._state.utms;
    }

    get reading() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.reading) {
            this._state.reading = {};
            this._persist();
        }
        return this._state.reading;
    }

    get authorSubscriptions() {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.authorSubscriptions) {
            this._state.authorSubscriptions = [];
            this._persist();
        }
        return this._state.authorSubscriptions;
    }

    saveEmail(email: string) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.email = email;
        this.email$.next(this._state.email);
        this._persist();
    }

    saveName(name: string) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.name = name;
        this._persist();
    }

    saveTiktok(tiktok: string) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.tiktok = tiktok.replace("@", "");
        this._persist();
    }

    saveFreeBook(bookSlug: string, options?: { altCoverIndex?: number; altReadableSlug?: string }) {
        if (!this._initialized) {
            this._restore();
        }
        if (!this._state.freeBooks.includes(bookSlug)) {
            this._state.freeBooks.push(bookSlug);
            if (options && Object.keys(options).length) {
                this._state.altMetadata = {
                    ...this._state.altMetadata,
                    [bookSlug]: options
                }
            }
            localStorage.setItem(`free-book-added-at-${bookSlug}`, Date.now().toString());
            this._persist();
        }
    }

    saveBonusContent(bookSlug: string, options: {finishedBonusScene?: boolean, finishedCharacterOverviews?: boolean} = {}) {
        if (!this._initialized) {
            this._restore();
        }
        this.state.bonusContent[bookSlug] = this.state.bonusContent[bookSlug] || {finishedBonusScene: false, finishedCharacterOverviews: false, rated: false};
        if (typeof options.finishedBonusScene === "boolean") {
            this.state.bonusContent[bookSlug].finishedBonusScene = options.finishedBonusScene;
        }
        if (typeof options.finishedCharacterOverviews === "boolean") {
            this.state.bonusContent[bookSlug].finishedCharacterOverviews = options.finishedCharacterOverviews;
        }
        this._persist();
    }

    savePrelaunch(route: string, val: {joinedPrelaunch: boolean}) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.prelaunches[route] = val;
        this._persist();
    }

    saveCampaign(route: string, val: {[key: string]: any}) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.campaigns[route] = val;
        this._persist();
    }

    saveSignupReason(reason: SignupReason) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.signupReason = reason;
        this._persist();
    }

    savePendingComment(comment: {discussion: string, content: string, threadId?: string, metadata?: {[key: string]: any}}) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.pendingComment = comment;
        this._persist();
    }

    clearPendingComment() {
        if (!this._initialized) {
            this._restore();
        }
        delete this._state.pendingComment
        this._persist();
    }

    saveUtms(utms: typeof this._state.utms) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.utms = utms;
        this._persist();
    }

    saveReading(readableSlug: string, update: {bookmark?: number, percent?: number, milestone?: number, finished?: boolean, downloaded?: boolean}) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.reading[readableSlug] = this._state.reading[readableSlug] || {milestones: []};
        if (typeof update.bookmark === "number") {
            this._state.reading[readableSlug].bookmark = update.bookmark;
        }
        if (typeof update.percent === "number") {
            this._state.reading[readableSlug].percent = update.percent;
        }
        if (typeof update.finished === "boolean") {
            this._state.reading[readableSlug].finished = update.finished;
        }
        if (typeof update.downloaded === "boolean") {
            this._state.reading[readableSlug].downloaded = update.downloaded;
        }
        if (update.milestone && !this._state.reading[readableSlug].milestones.includes(update.milestone)) {
            this._state.reading[readableSlug].milestones.push(update.milestone);
        }
        this._persist();
    }

    saveAuthorSubscription(authorSlug: string) {
        if (!this._initialized) {
            this._restore();
        }
        this._state.authorSubscriptions = this._state.authorSubscriptions || [];
        if (!this._state.authorSubscriptions.includes(authorSlug)) {
            this._state.authorSubscriptions.push(authorSlug);
        }
        this._persist();
    }

    private _restore() {
        if (!this._platformService.isBrowser()) return;
        this._initialized = true;
        try {
            if (localStorage.getItem("AnonymousPersistentState")) {
                this._state = JSON.parse(localStorage.getItem("AnonymousPersistentState")!);
                if (this._state.email) {
                    this.email$.next(this._state.email);
                }
            }
        } catch (_e) {}
    }

    private _persist() {
        if (!this._platformService.isBrowser()) return;
        try {
            localStorage.setItem("AnonymousPersistentState", JSON.stringify(this._state));
        } catch (_e) {}
    }
}
