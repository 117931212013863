import { Component, Input } from '@angular/core';
import { Book } from 'src/app/models/book.model';

@Component({
    selector: "worldmaker-component-new-release",
    templateUrl: "./new-release.component.html",
    styleUrl: "./new-release.component.scss"
})
export class NewRelease {
    @Input() data!: {
        book: Book;
        showHR: boolean;
        placement?: string;
    };

    constructor() {}
}