<ng-container *ngIf="book">
    @if (mode === "rate_book") {
    <div class="container-xxl">
        <div class="row c-pt-20 c-pb-32 d-none d-lg-block">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <img src="/client/assets/logos/cf-logo-1-1.svg" alt="logo" width="160" height="35">
            </div>
        </div>
        <div class="row c-pb-24 c-pt-64 c-pt-lg-32">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                <img [src]="book.images!.cover | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleHeight:160 | cloudinaryUrl"
                    [alt]="book.title" preloadImage loading="eager" fetchPriority="high" height="160"
                    class="cover c-mb-8">
                <span class="author-header">By {{book.mainAuthorName}}</span>
            </div>
        </div>
        <div class="row c-pb-20 c-mb-120">
            <div class="col-12 d-flex justify-content-center">
                <div class="hero-section d-flex flex-column justify-content-center align-items-center">
                    <span class="rating-header c-mb-8">How was this book?</span>
                    <span class="rating-subheader c-mb-8">Rate it to get recommendations and help the author</span>
                    <add-star-rating [value]="rating" (valueChange)="addRating($event)"></add-star-rating>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="d-flex flex-column justify-content-center align-items-center c-px-20 c-my-20">
            <button class="btn btn-primary btn-round btn-custom c-mb-16" [disabled]="!rating || loading" (click)="submitRating()">
                <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                <span>Submit rating</span>
            </button>
            <a class="skip" (click)="skip()">Skip</a>
        </div>
    </div>
    } @else if (mode === "claim_free_book") {
    <div class="container-xxl">
        <div class="row c-pt-20 c-pb-32 d-none d-lg-block">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <img src="/client/assets/logos/cf-logo-1-1.svg" alt="logo" width="160" height="35">
            </div>
        </div>
        <div class="row c-pb-24 c-pt-64 c-pt-lg-32">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                <img [src]="giftImage" height="225" class="animation">
            </div>
        </div>
        @defer (on timer(1600ms)) {
        <div class="row c-pb-20 c-mb-120">
            <div class="col-12 d-flex justify-content-center">
                <div class="hero-section d-flex flex-column justify-content-center align-items-center">
                    <span class="claim-header c-mb-8">You’re amazing!</span>
                    <span class="claim-subheader c-mb-8">
                        You’ve <span class="orange">earned a free book</span> for reading the {{contentType === 'bonus_scene' ? 'bonus scene' : 'character overviews'}} of {{book.title}}!
                    </span>
                </div>
            </div>
        </div>
        }
    </div>
    @defer (on timer(1600ms)) {
    <div class="footer">
        <div class="d-flex flex-column justify-content-center align-items-center c-px-20 c-my-20">
            <button class="btn btn-primary btn-round btn-custom c-mb-16" (click)="goToFreeBookSelect()">
                Claim free book
            </button>
            <a class="skip" (click)="skip()">Skip</a>
        </div>
    </div>
    }
    } @else if (mode === "recommendations") {
    <div class="container-xxl">
        <div class="row c-pt-20 c-pb-32" (click)="explore()">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <img src="/client/assets/logos/cf-logo-1-1.svg" alt="logo" width="160" height="35">
            </div>
        </div>
        <div class="row c-pt-20 c-pb-32">
            <worldmaker-component-book-recommendations [data]="{
                headerText: 'Top picks for you',
                featuredNewReleaseMode: false,
                featuredGenre: '',
                hideLatestRelease: false,
                showHR: false,
                placement: 'rate_book_page',
                book
            }"></worldmaker-component-book-recommendations>
        </div>
        <div class="row c-mt-10 c-mb-32">
            <div class="col d-flex justify-content-center">
                <button class="btn btn-primary btn-round btn-custom c-mb-16" (click)="explore()">
                    Explore more books
                </button>
            </div>
        </div>
    </div>
    } @else {
    <div class="container-xxl c-mb-160">
        <div class="row c-pt-20 d-none d-lg-block">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <img src="/client/assets/logos/cf-logo-1-1.svg" alt="logo" width="160" height="35">
            </div>
        </div>
        <div class="row c-pt-32 c-pt-lg-64 c-pb-24">
            <h2 class="pick-free-book-header text-center">Choose your romance gift</h2>
        </div>
        <div class="row vertical-collection">
            <div class="col-12 col-lg-6" *ngFor="let arc of availableARCs">
                <ng-container [ngTemplateOutlet]="featuredArc" [ngTemplateOutletContext]="{book: arc.book, slots: arc.slots}"></ng-container>
            </div>
            <div class="col-12 col-lg-6" *ngFor="let freeBook of availableFreeBooks">
                <ng-container [ngTemplateOutlet]="featured" [ngTemplateOutletContext]="{book: freeBook}"></ng-container>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="d-flex flex-column justify-content-center align-items-center c-px-20 c-my-20">
            <button class="btn btn-primary btn-round btn-custom c-mb-16" [disabled]="!selectedFreeBook || loading"
                (click)="finishFlow()">
                <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                <span>Read now</span>
            </button>
            <a class="skip" (click)="skip()">Skip</a>
        </div>
    </div>
    }
</ng-container>

<ng-template #featured let-book="book">
    <div class="featured d-flex pointy" (click)="selectFreeBook(book)">
        <div class="d-flex justify-content-center align-items-center">
            <img [src]="book.images.cover | cloudinaryScaleHeight:320 | cloudinaryQuality:100 | cloudinaryFormat | cloudinaryUrl"
                [alt]="book.title" class="featured-image">
        </div>
        <div class="d-flex flex-column book-info c-pt-xl-60 c-pt-24 w-100">
            <span class="book-title c-mb-4">{{book.title}}</span>
            <span class="author c-mb-8">{{book.mainAuthorName}}</span>
            <span class="labels flex-grow-1">
                <span *ngIf="mainGenre(book)">{{mainGenre(book)!.name}}{{book.mainTrope || spicyMeterLabel(book) ? ',' :
                    ''}}</span>
                <span *ngIf="book.mainTrope">{{book.mainTrope.name}}{{spicyMeterLabel(book) ? ',' : ''}}</span>
                <span *ngIf="spicyMeterLabel(book)">{{spicyMeterLabel(book)}}</span>
            </span>
            <div class="d-flex justify-content-end align-items-center c-pb-4 c-pb-lg-16">
                <button class="btn btn-round btn-select {{selectedFreeBook === book ? 'btn-dark' : 'btn-white'}}"
                    (click)="selectFreeBook(book)">
                    {{selectedFreeBook === book ? "Selected" : "Select"}}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #featuredArc let-book="book" let-slots="slots">
    <div class="featured d-flex pointy" (click)="selectFreeBook(book)">
        <div class="d-flex justify-content-center align-items-center">
            <img [src]="book.images.cover | cloudinaryScaleHeight:320 | cloudinaryQuality:100 | cloudinaryFormat | cloudinaryUrl"
                [alt]="book.title" class="featured-image">
        </div>
        <div class="d-flex flex-column book-info c-pt-xl-40 c-pt-16 w-100">
            <fomo-countdown colorScheme="red" [to]="book.publishedAt!" [showCountDown]="true" size="small"></fomo-countdown>
            <span class="book-title c-mb-4">{{book.title}}</span>
            <span class="author c-mb-8">{{book.mainAuthorName}}</span>
            <span class="labels flex-grow-1">
                <span *ngIf="mainGenre(book)">{{mainGenre(book)!.name}}{{book.mainTrope || spicyMeterLabel(book) ? ',' :
                    ''}}</span>
                <span *ngIf="book.mainTrope">{{book.mainTrope.name}}{{spicyMeterLabel(book) ? ',' : ''}}</span>
                <span *ngIf="spicyMeterLabel(book)">{{spicyMeterLabel(book)}}</span>
            </span>
            <div class="d-flex justify-content-between align-items-center c-pb-4 c-pb-lg-16">
                <span class="arc-left c-me-4">{{slots.left}}/{{slots.total}} copies left</span>
                <button class="btn btn-round btn-select {{selectedFreeBook === book ? 'btn-dark' : 'btn-white'}}"
                    (click)="selectFreeBook(book)">
                    {{selectedFreeBook === book ? "Selected" : "Select"}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
