import { Component, Input, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Book } from 'src/app/models/book.model';
import { PlatformService } from 'src/app/services/platform.service';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { combineLatest, filter, firstValueFrom } from 'rxjs';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from 'src/app/services/canonical.service';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { BookSeoService } from 'src/app/services/book-seo.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import dayjs from 'dayjs';

@Component({
    selector: 'book-header',
    templateUrl: './book-header.component.html',
    styleUrls: ['./book-header.component.scss'],
    animations: [
        trigger(
          'stickyFooterAnimation', 
          [
            transition(
              ':enter', 
              [
                style({ "max-height": 0 }),
                animate('1s ease-out', style({ "max-height": "250px" }))
              ]
            ),
            transition(
              ':leave', 
              [
                style({ "max-height": "250px" }),
                animate('1s ease-in', style({ "max-height": 0 }))
              ]
            )
          ]
        )
    ]
})
export class BookHeader {
    @Input() book!: Book;
    joinCrewImage?: {
        channel: 'image' | 'manual_image';
        data: { url: string; description: string, category: string };
    };
    @Input() headerText = "";
    @Input() amazonCtaText = "Read now";
    @Input() showAmazonCta?: boolean;
    @Input() enableStickyFooter?: boolean;
    @Input() enableRating: boolean = false;
    @Input() showSpicyMeter: boolean = true;
    @Input() showHeader: boolean = true;
    @Input() altCoverIndex?: string;

    scrolled = false;
    followsAuthor = false;
    joinedCrew = false;
    afterJoinCb = () => this._modalService.dismissAll();

    @ViewChild('joinModal') joinModal!: TemplateRef<any>;

    followRequestInProgress$ = this._activatedRoute.queryParams.pipe(
        filter(params => params["action"] === "follow_author" && params["author"] === this.mainAuthor.slug),
        filter(() => this._platformService.isBrowser())
    );
    
    constructor(
        private readonly _platformService: PlatformService,
        private readonly _followService: FollowService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _canonicalService: CanonicalService,
        private readonly _modalService: NgbModal,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _router: Router,
        private readonly _bookSeoService: BookSeoService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    async ngOnInit() {
        this._followService.followsAuthor$.subscribe(val => {
            this.followsAuthor = val;
        });
        if (this.mainAuthor.crew) {
            this._followService.joinedCrew$.subscribe(val => {
                this.joinedCrew = val;
            });
        }
        await this._followService.init(
            this.book,
            this.mainAuthor,
            null
        );
        this._analyticsService.trackPageView(undefined, "Book Page", {
            book: this.book.slug,
            author: this.book.mainAuthorSlug,
            mode: this.book.isPrelaunched ? "prelaunch" : "live",
            publisher: this.book.publisher || undefined
        });

        if (!this._router.url.includes("/pages")) {
            this._canonicalService.set(`${environment.baseUrl}${this._utilitiesService.getBookUrl(this.book)}`);
        }

        if (this.book.publishedAt && dayjs(this.book.publishedAt).isAfter(dayjs())) {
            this.amazonCtaText = "Pre-order now";
        }

        combineLatest([
            this._authService.isLoggedIn$,
            this._authService.onboardingCompleted$,
            this.followRequestInProgress$
        ]).pipe(
            filter(([isLoggedIn, onboardingCompleted, followInProgress]) => isLoggedIn && onboardingCompleted && Boolean(followInProgress)),
        ).subscribe(() => {
            this.followAuthor();
        });
    }

    get mainAuthor() {
        const main = this.book.authors.find(
            (author) => author.slug === this.book.mainAuthorSlug
        );
        if (main) {
            return main;
        } else {
            return this.book.authors[0];
        }
    }

    get enableAmazonButton() {
        if (this.showAmazonCta && this.amazonCtaText && (this.book.links.amazon || this.book.shortAmazonLink)) {
            return true;
        } else {
            return false;
        }
    }

    get coverSrc(): string {
        try {
            let src;
            if (this.altCoverIndex !== undefined && Number(this.altCoverIndex) >= 0 && this.book.images.altCovers?.length) {
                src = this.book.images.altCovers[Number(this.altCoverIndex)];
            }
            else {
                src = this.book.images.cover;
            }
            //only overwrite it on normal book pages, not landing pages
            if (!this._router.url.match("/pages/")) {
                this._platformService.metaTags.setOpenGraphImage(src);
            }
            return src;
        }
        catch(e) {
            console.warn("Error in book cover source", e);
            return this.book.images.cover;
        }
    }

    get isMobile() {
        return this._platformService.isMobile;
    }

    get crewFormSettings() {
        return {
            trigger: "author_follow",
            joinCrewImage: this.joinCrewImage
        } as const;
    }

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        return {
            joinedFrom: "book_page",
            book: this.book.slug,
            mode: this.book.isPrelaunched ? "prelaunch" : "live"
        };
    }

    @HostListener("window:scroll")
    async onWindowScroll() {
        if (!this.enableStickyFooter) return;
        const headerHeight = parseInt(getComputedStyle(document.querySelector(".book-header-container")!).height.replace("px", ""));
        if (window.scrollY >= headerHeight) {
            this.scrolled = true;
            const sticky = document.querySelector(".sticky-container");
            if (sticky) {
                setTimeout(() => {
                    const stickyHeight = getComputedStyle(sticky).height;
                    const stickyHeightNumber = parseInt(stickyHeight.replace("px", ""));
                    this._chatWidgetService.slideUp(stickyHeightNumber + 10);
                }, 1200);
            }
        } else {
            this.scrolled = false;
            setTimeout(() => {
                this._chatWidgetService.resetPosition();
            }, 500);
        }
    }

    trackAmazonClick() {
        this._analyticsService.track({event: "go_to_amazon", params: {bookOrseries: this.book, clickSource: "web"}});
    }

    async followAuthor() {
        if (!this._authService.user) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "book_page_follow_author",
                        author: this.book.mainAuthorSlug,
                        book: this.book.slug
                    }
                }
            });
            await this._authService.login({
                sendStageTitle: `Sign in or sign up`,
                sendStageDescription: `Sign-in or register for free to follow ${this.mainAuthor.name}`,
                skipOnboarding: false,
                signupReason: {type: "follow_author", author: this.mainAuthor.slug},
                action: "follow_author",
                actionParams: {
                    author: this.mainAuthor.slug
                }
            });
        }
        this._followService.followAuthor({source: "book_page", book: this.book.slug});

        if (!this.joinedCrew && this.mainAuthor.crew) {
            this._chatWidgetService.hide();
            const that = this;
            //TODO make modalService.close() to wait for modal hidden event
            await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
            await firstValueFrom(
                this._modalService.open(this.joinModal, {
                    centered: true,
                    fullscreen: await firstValueFrom(this._platformService.isMobile),
                    beforeDismiss() {
                        that._chatWidgetService.show();
                        return true;
                    },
                }).shown
            );
        }
    }
    async unfollowAuthor() {
        await this._followService.unfollowAuthor();
    }
}
