<div class="full-page">
    <div *ngIf="!whiteLabel" [ngClass]="{
        'header-wrapper': true,
        'bg-transparent': isTransparent,
        'fixed-header': !whiteLabel
    }">
        <app-header></app-header>
    </div>
    
    <main class="overflow-x-hidden c-pb-24" [ngClass]="{
        'with-fixed-header': !whiteLabel,
        'p-0': whiteLabel
    }">
        <router-outlet></router-outlet>
    </main>

    @if (!whiteLabel) {
        <app-footer></app-footer>
    }
</div>
<chat-widget-facade></chat-widget-facade>