import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import emailSpellChecker from '@zootools/email-spell-checker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Book } from 'src/app/models/book.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { firstValueFrom, interval, Subscription } from 'rxjs';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { Readable } from 'src/app/models/readable.model';
import { ReadablesService } from 'src/app/modules/readables/services/readables.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CanonicalService } from 'src/app/services/canonical.service';
import { environment } from 'src/environments/environment';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import { BooksService } from 'src/app/services/books.service';

@Component({
    selector: 'worldmaker-component-arc-cover-header',
    templateUrl: './arc-cover-header.component.html',
    styleUrls: ['./arc-cover-header.component.scss'],
    animations: [
        trigger(
          'stickyFooterAnimation', 
          [
            transition(
              ':enter', 
              [
                style({ "max-height": 0 }),
                animate('1s ease-out', style({ "max-height": "275px" }))
              ]
            ),
            transition(
              ':leave', 
              [
                style({ "max-height": "275px" }),
                animate('1s ease-in', style({ "max-height": 0 }))
              ]
            )
          ]
        ),
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        )
    ]
})
export class ArcCoverHeader implements OnInit, OnDestroy {
    @Input() data!: {
        book: Book;
        joinCrewImage?: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        };
        recommendedBooks: Book[];
        ctaText: string;
        headerText: string;
        showHeader: boolean;
        enableStickyFooter: boolean;
        enableBreadcrumbs: boolean;
        showRating: boolean;
        showSpicyMeter: boolean;
        acValuePostfix?: string;
        altCoverIndex?: string;
        altReadableSlug?: string;
    };
    @Input() joinedArc!: boolean;
    @Input() arcSlots!: {left: number, total: number};
    book!: Book;
    readable!: Readable;
    authChecked$ = this._authService.browserAuthCheckPassed$;

    loading = false;
    scrolled = false;
    emailSuggestionError = "";
    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    isLoggedIn = false;

    subs: Subscription[] = [];

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _readablesService: ReadablesService,
        private readonly _booksService: BooksService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _canonicalService: CanonicalService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    async ngOnInit() {
        let email = this._anonymousPersistentState.email;
        email = (await firstValueFrom(this._route.queryParamMap)).get("email") || email;
        if (this._authService.user) {
            email = this._authService.user.email;
        }
        if (email) {
            this.emailFormControl.setValue(email);
        }
        this.book = this.data.book;
        if (!this._router.url.includes("/pages")) {
            this._canonicalService.set(`${environment.baseUrl}${this._utilitiesService.getBookUrl(this.book)}`);
        }
        this._analyticsService.trackPageView(undefined, this._router.url.match("/pages/") ? "Landing Page" : "Book Page", {
            book: this.book.slug,
            author: this.book.mainAuthorSlug,
            mode: "arc",
            publisher: this.book.publisher || undefined
        });
        //go to the actual book page if we are on /pages and they already have the book
        if (this.joinedArc && this._router.url.match("/pages/")) {
            return this._router.navigate([this._utilitiesService.getBookUrl(this.book)]);
        }
        this._authService.isLoggedIn$.subscribe((status) => {
            this.isLoggedIn = status;
        });
        // if they have the arc already, get the readable so they can read here
        if (this.joinedArc) {
            const arc = await firstValueFrom(this._readablesService.infoByBookSlug(this.book.slug, "arc"));
            if (arc) {
                this.readable = arc;
            }
        }
        return;
    }

    ngOnDestroy(): void {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    @HostListener("window:scroll")
    async onWindowScroll() {
        if (!this.data.enableStickyFooter) return;
        const headerHeight = parseInt(getComputedStyle(document.querySelector(".book-header-container")!).height.replace("px", ""));
        if (window.scrollY >= headerHeight) {
            this.scrolled = true;
            const sticky = document.querySelector(".sticky-container");
            if (sticky) {
                setTimeout(() => {
                    const stickyHeight = getComputedStyle(sticky).height;
                    const stickyHeightNumber = parseInt(stickyHeight.replace("px", ""));
                    this._chatWidgetService.slideUp(stickyHeightNumber + 10);
                }, 1200);
            }
        } else {
            this.scrolled = false;
            setTimeout(() => {
                this._chatWidgetService.resetPosition();
            }, 500);
        }
    }

    get mainAuthor() {
        const main = this.book.authors.find(
            (author) => author.slug === this.book.mainAuthorSlug
        );
        if (main) {
            return main;
        } else {
            return this.book.authors[0];
        }
    }

    get coverSrc(): string {
        try {
            let src;
            if (this.data.altCoverIndex !== undefined && Number(this.data.altCoverIndex) >= 0 && this.book.images.altCovers?.length) {
                src = this.book.images.altCovers[Number(this.data.altCoverIndex)];
            }
            else {
                src = this.book.images.cover;
            }
            //only overwrite it on normal book pages, not landing pages
            if (!this._router.url.match("/pages/")) {
                this._platformService.metaTags.setOpenGraphImage(src);
            }
            return src;
        }
        catch(e) {
            console.warn("Error in book cover source", e);
            return this.book.images.cover;
        }
    }

    get isMobile() {
        return this._platformService.isMobile;
    }

    async readNow() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;

        this._anonymousPersistentState.saveEmail(email);

        this.loading = true;
        await this._analyticsService.identifyAndTrack({
            email,
            traits: {signupReason: {type: "prelaunch_flow", book: this.book.slug}},
        }, {event: "get_free_book", params: {book: this.book, type: "arc", placement: "book_page"}});
        await firstValueFrom(this._booksService.joinArc(this.book.slug, this._anonymousPersistentState.email));
        localStorage.setItem(`free-book-added-at-${this.book.slug}`, Date.now().toString());
        this.loading = false;

        this._router.navigate(["/library/shelves/free"], {state: {freeFlowNotification: true}});

        //restore chat
        this._chatWidgetService.resetPosition();
    }

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    onStickyInputClicked(event: Event) {
        setTimeout(() => {
            this._utilitiesService.scrollStickyContainerVisible(event.target as HTMLElement);
        }, 500);
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";

        }
    }
}
