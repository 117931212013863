import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { firstValueFrom, combineLatest, filter, map, shareReplay, Observable, Subscription } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { TitleService } from 'src/app/services/title.service';
import { JsonLdService } from 'src/app/services/json-ld.service';
import { CanonicalService } from 'src/app/services/canonical.service';
import { environment } from 'src/environments/environment';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { Book } from 'src/app/models/book.model';
import { BooksService } from 'src/app/services/books.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { LibraryStateService } from 'src/app/state/library.state.service';

@Component({
    selector: 'rate-book-page',
    templateUrl: './rate-book-page.component.html',
    styleUrls: ['./rate-book-page.component.scss']
})
export class RateBookPageComponent implements OnInit, OnDestroy {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly _booksService: BooksService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _titleService: TitleService,
        private readonly _jsonLdService: JsonLdService,
        private readonly _canonicalService: CanonicalService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _authService: EmbeddedAuthService,
        private readonly _libraryStateService: LibraryStateService
    ) {}

    afterKindle$ = this._route.queryParams.pipe(
        filter(params => params["action"] === "after_kindle"),
        filter(() => this._platformService.isBrowser())
    );

    bonusScene$: Observable<boolean> = this._route.queryParams.pipe(
        map(params => params["action"] === "bonus_scene"),
        shareReplay(1)
    );

    characterOverviews$: Observable<boolean> = this._route.queryParams.pipe(
        map(params => params["action"] === "character_overviews"),
        shareReplay(1)
    );

    freeBook$: Observable<boolean> = this._route.queryParams.pipe(
        map(params => params["action"] === "free_book"),
        shareReplay(1)
    );

    mode: "rate_book" | "claim_free_book" | "recommendations" | "select_free_book" = "rate_book";
    contentType!: "bonus_scene" | "character_overviews" | "free_book" | "other";
    book!: Book;
    rating = 0;
    availableFreeBooks!: Book[];
    selectedFreeBook: Book | null = null;
    availableARCs!: {book: Book, slots: {left: number, total: number}}[];
    subs: Subscription[] = [];
    loading = false;

    readonly giftImage = "https://res.cloudinary.com/dap6pju8g/image/upload/fl_lossy/q_100/c_scale,h_225/v1730205544/book-media/nnwlryr7ocezftvfhady.gif";

    async ngOnInit() {
        this._platformService.metaTags.setNoIndex();

        const params = await firstValueFrom(this._route.params);
        const bookSlug = params["book-slug"];
        if (!bookSlug) {
            this._router.navigate(["/"]);
            return;
        }
        try {
            this.book = await firstValueFrom(this._booksService.findOneBySlug(bookSlug));
        } catch (_) {
            this._router.navigate(["/"]);
            return;
        }
        this._addMetaTags();
        const isBonusScene = await firstValueFrom(this.bonusScene$);
        const isCharacterOverviews = await firstValueFrom(this.characterOverviews$);
        const isFreebook = await firstValueFrom(this.freeBook$);
        if (isBonusScene) {
            this.contentType = "bonus_scene";
        } else if (isCharacterOverviews) {
            this.contentType = "character_overviews";
        } else if (isFreebook) {
            this.contentType = "free_book";
        } else {
            this.contentType = "other";
        }
        this._analyticsService.trackPageView(undefined, "Rate Book Page", {
            book: this.book.slug,
            author: this.book.mainAuthorSlug,
            publisher: this.book.publisher || undefined,
            contentType: this.contentType
        });
        
        if (this._platformService.isBrowser()) {
            //redirect them to book page if we have no user
            if (!this._authService.user && !this._anonymousPersistentState.email) {
                this._router.navigate([this._utilitiesService.getBookUrl(this.book)]);
                return;
            }

            //show them recommendations if they have already rated
            const rating = await firstValueFrom(this._booksService.getBookRating(this.book.id!, this._anonymousPersistentState.email));
            if (rating) {
                this.mode = "recommendations";
                return;
            }

            if (this.contentType === "bonus_scene" || this.contentType === "character_overviews") {
                //check what available free books they have (anonymous and loggedin)
                const allFreeBooks = await firstValueFrom(this._booksService.getFreeBooksForBonusFlow(this.book.slug));
                this.availableFreeBooks = allFreeBooks.filter(b => !this._anonymousPersistentState.freeBooks.find(bb => bb === b.slug)).slice(0, 6);
                this.subs.push(combineLatest([this._authService.isLoggedIn$, this._libraryStateService.freeShelf$])
                    .pipe(filter(([isLoggedIn, shelf]) => isLoggedIn && !!shelf))
                    .subscribe(([isLoggedIn, shelf]) => {
                        if (isLoggedIn && shelf) {
                            this.availableFreeBooks = allFreeBooks.filter(b => !shelf.books.find(bb => bb.slug === b.slug)).slice(0, 6);
                        }
                    }));
                //get their available ARCs
                this.availableARCs = await firstValueFrom(this._booksService.getAvailableArcs({
                    email: this._anonymousPersistentState.email,
                    arcsFor: this.book.slug
                }));
                //preload the gift animation here to be ready
                this._utilitiesService.preloadImage(this.giftImage);
            }
        }

        //unlock web content when they come from kindle flow
        this.subs.push(this.afterKindle$.subscribe(async () => {
            this._anonymousPersistentState.saveBonusContent(this.book.slug, {finishedBonusScene: true, finishedCharacterOverviews: true});
            const params = await firstValueFrom(this._route.queryParams);
            this.contentType = params["contentType"] || "other";
        }));
    }

    ngOnDestroy(): void {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    addRating(rating: number) {
        if (rating) {
            this.rating = rating;
        }
    }

    async submitRating() {
        if (!this.rating) return;
        this.loading = true;
        await firstValueFrom(this._booksService.rateBook(this.book.id!, this.rating, this._anonymousPersistentState.email));
        this._analyticsService.track({
            event: "continue_bonus_content_flow",
            params: {
                book: this.book,
                step: this.mode,
                contentType: this.contentType
            }
        });
        this.loading = false;
        if (this.contentType === "bonus_scene" || this.contentType === "character_overviews") {
            if (this.availableFreeBooks.length > 0 || this.availableARCs.length > 0) {
                this.mode = "claim_free_book";
            } else {
                //if they have all of our free books show them recommendations
                this.mode = "recommendations";
            }
        } else {
            this.mode = "recommendations";
        }

    }

    skip() {
        this._analyticsService.track({
            event: "skip_bonus_content_flow",
            params: {
                book: this.book,
                step: this.mode,
                contentType: this.contentType
            }
        });
        //they can skip the rating but continue the flow
        //if they skip at a later step, they just go to recommendations
        if (this.mode === "rate_book") {
            if (this.contentType === "bonus_scene" || this.contentType === "character_overviews") {
                if (this.availableFreeBooks.length > 0 || this.availableARCs.length > 0) {
                    this.mode = "claim_free_book";
                } else {
                    //if they have all of our free books show them recommendations
                    this.mode = "recommendations";
                }
            } else {
                this.mode = "recommendations";
            }
        } else {
            // this._router.navigate(["/"]);
            this.mode = "recommendations";
        }
    }

    goToFreeBookSelect() {
        this._analyticsService.track({
            event: "continue_bonus_content_flow",
            params: {
                book: this.book,
                step: this.mode,
                contentType: this.contentType
            }
        });
        this.mode = "select_free_book";
    }

    selectFreeBook(book: Book) {
        this.selectedFreeBook = book;
    }

    async finishFlow() {
        if (!this.selectedFreeBook) return;
        if (this.availableARCs.find(arc => arc.book.slug === this.selectedFreeBook!.slug)) {
            this.loading = true;
            await firstValueFrom(this._booksService.joinArc(this.selectedFreeBook.slug, this._anonymousPersistentState.email));
            localStorage.setItem(`free-book-added-at-${this.selectedFreeBook.slug}`, Date.now().toString());
            this._analyticsService.track({event: "get_free_book", params: {book: this.selectedFreeBook, type: "arc", placement: "bonus_content_flow"}});
            this.loading = false;
        } else {
            this._anonymousPersistentState.saveFreeBook(this.selectedFreeBook.slug);
            this._analyticsService.track({event: "get_free_book", params: {book: this.selectedFreeBook, type: "free_book", placement: "bonus_content_flow"}});
        }
        this._analyticsService.track({
            event: "finish_bonus_content_flow",
            params: {
                book: this.book,
                contentType: this.contentType
            }
        });
        this._router.navigate(["/library/shelves/free"], {state: {bonusFlowNotification: true}});
    }

    //try for scottish or regency first
    //then historical
    //and then fallback to any genre that has 2 words (which are usually really specific)
    mainGenre(book: Book) {
        const scottishOrRegency = book.genres.find(genre => genre.slug === "scottish-romance" || genre.slug === "regency");
        if (scottishOrRegency) {
            return scottishOrRegency;
        }
        const historical = book.genres.find(genre => genre.slug === "historical-romance");
        if (historical) {
            return historical;
        }
        return book.genres.find(genre => genre.name.split(" ").length === 2)
    }

    spicyMeterLabel(book: Book) {
        switch (book.spicyMeter) {
            case 1:
                return "Sweet"
            case 2:
                return "Sensual";
            case 3:
                return "Sexy";
            case 4:
                return "Steamy";
            case 5:
                return "Spicy";
            default:
                return "";
        }
    }

    explore() {
        this._router.navigate(["/"]);
    }

    private _addMetaTags() {
        const title = this.book.title;
        this._titleService.setTitle(title);
        this._platformService.metaTags.setTitle(title);
        this._platformService.metaTags.setDescription(this.book.description!);
        this._platformService.metaTags.setOpenGraphImage(this.book.images.cover);
        this._jsonLdService.addWebPage({
            name: title,
            url: this._platformService.currentUrl,
            description: this.book.description!,
        });
        this._canonicalService.set(`${environment.baseUrl}${this._utilitiesService.getBookUrl(this.book)}`);
    }
}