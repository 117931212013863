import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BadgeConfig } from 'src/app/models/badge-config.model';


@Component({
  selector: 'badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeListComponent implements OnInit {
    private _data: BadgeConfig[] = [];
    
    get data(): BadgeConfig[] {
        return this._data;
    }

    @Input() 
    set data(v) {
        if (JSON.stringify(this._data) != JSON.stringify(v)) {
            this._data = v;
            this.refresh();
        }
    }

    @Input()
    expandable = false;

    @Input()
    showInitial = 6;

    @Input()
    expandCta = "View More";

    @Input()
    disableClick = false;

    @Input()
    horizontalMode = false;

    list!: BadgeConfig[];
    expanded = false;

    ngOnInit(): void {
        this.refresh();
    }

    private refresh() {
        if (this.expandable) {
            this.list = this.data.slice(0, this.showInitial);
        }
        else {
            this.list = this.data;
        }
    }

    expand() {
        this.expanded = true;
        this.list.push(...this.data.slice(this.showInitial, this.data.length));
    }

    badgeClick(item: BadgeConfig) {
        if (item.onClick) {
            item.onClick();
        }
    }

    isImageLink(image: string) {
        if (image.startsWith("http")) {
            return true;
        } else {
            return false;
        }
    }
}
