@defer (when ready) {
    @if (readerMagnetMode === "free_book") {
        @if (hasFreeBook && !previewMode) {
            <div class="col-12" [@fadeIn]>
                <button type="submit" class="btn btn-dark btn-round btn-large btn-with-icon w-100" (click)="goToLibrary()">
                    <feather-icon icon="book"></feather-icon>
                    Start Reading
                </button>
            </div>
        } @else {
            <ng-container [ngTemplateOutlet]="form"></ng-container>
        }
    } @else {
        @if (simpleFormThankYou && !previewMode) {
            <div class="col-12 d-flex justify-content-center c-pb-16" [@fadeIn]>
                <span>Welcome! Glad to have you onboard. Stay tuned for more updates!</span>
            </div>
        } @else {
            <ng-container [ngTemplateOutlet]="form"></ng-container>
        }
    }
} @placeholder {
    <div class="d-flex justify-content-center align-items-center w-100">
        <!-- <span
            class="spinner-border"
            role="status"
            aria-hidden="true"
        ></span> -->
    </div>
}

<ng-template #form>
    <form [formGroup]="emailForm" (ngSubmit)="submit()" [@fadeIn]>
        <div class="col-12 c-pb-12">
            <input type="email" required class="form-control rounded-input dark-input input-large" formControlName="email"
                placeholder="Enter your email" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)">
        </div>
        <div class="col-12">
            <button type="submit" class="btn btn-dark btn-round btn-large w-100" [disabled]="subscribing">
                <span
                    class="spinner-border spinner-border-sm c-me-8"
                    role="status"
                    aria-hidden="true"
                    *ngIf="subscribing"
                ></span>
                <span>{{ buttonText }}</span>
            </button>
        </div>
        <div class="col-12 form-errors c-pt-12">
            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid && !emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                <small>
                    {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                </small>
            </div>
            <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                <small>
                    Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                </small>
            </div>
            <!-- <div class="c-mt-8" style="min-height: 16px;" *ngIf="(!emailForm.invalid || !emailForm.dirty) && !emailSuggestionError"></div> -->
        </div>
    </form>
</ng-template>