import { AfterViewInit, Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy  {

    isLoggedIn: boolean = false;
    user: User | undefined = undefined;

    blogUrl: string = environment.blogUrl;

    private _subs: Subscription[] = [];

    isSplashScreen: boolean = false;

    isExplore: boolean = false;

    linksDisabled: boolean = false;

    constructor(
        private _platform: PlatformService,
        private _router: Router,
        private _userService: UserService,
        private _embeddedAuthService: EmbeddedAuthService,
        private _analyticsService: AnalyticsService
    ) {}

    ngAfterViewInit(): void {
        this.watchAuthStatus();
    }

    private watchAuthStatus() {
        this._subs.push(this._userService.isLoggedIn$.subscribe(loggedIn => {
            this.isLoggedIn = loggedIn;
        }));

        this._subs.push(this._userService.user$.subscribe(user => {
            this.user = user;
        }));
    }

    ngOnDestroy(): void {
        this._subs.forEach(s => s.unsubscribe());
    }
    
    ngOnInit(): void {
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            if (event instanceof NavigationEnd) {
                const url = (new URL(`${environment.baseUrl}${event.urlAfterRedirects}`)).pathname;
                this.isSplashScreen = url.startsWith("/author-pages");
                this.isExplore = url === "/explore" || url === "/";
                this.linksDisabled = url.startsWith("/pages");
            }
        });
    }

    scrolledPastHeader = false;
    @HostListener("window:scroll")
    onWindowScroll() {
        if (window.scrollY > 80) {
            this.scrolledPastHeader = true;
        } else {
            this.scrolledPastHeader = false;
        }
    }

    async login() {
        try {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "navbar"
                    }
                }
            });
            this._embeddedAuthService.login({
                signupReason: {type: "generic"},
                skipOnboarding: false
            });
        }
        catch (err) {
            console.error(err);    
        }
    }

    async logout() {
        this._analyticsService.track({event: "sign_out"});
        //give some time for the event to fire
        setTimeout(() => {
            this._embeddedAuthService.logout();
        }, 100);
    }

    trackBlogVisit() {
        this._analyticsService.track({event: "click_blog"});
    }
}
