import { Injectable } from '@angular/core';
import type { Auth0LockPasswordless } from "auth0-lock";
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { environment } from 'src/environments/environment';
import { Auth0User } from '../types/auth0-user';
import { IAuthService } from '../types/iauth.service';
import { Auth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from "jwt-decode";
import * as dayjs from "dayjs";
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'src/app/services/modal.service';
import { PasswordlessAuthComponent } from '../components/passwordless-auth/passwordless-auth.component';
import { PublicUserProfileService } from 'src/app/services/public-user-profile.service';
import { OnboardingStage, UserProfile } from 'src/app/models/user-profile.model';
import { ChangeNameComponent } from '../components/change-name/change-name.component';
import { ChangeProfilePictureComponent } from '../components/change-profile-picture/change-profile-picture.component';
import { LibraryStateService } from 'src/app/state/library.state.service';
import { PasswordlessAuthStage } from '../components/passwordless-auth/passwordless-auth.component';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { SignupReason } from 'src/app/services/analytics/events/signupReason';

export type PasswordlessSendMethod = "link" | "code";

export interface AuthModalOptions {
    sendStageTitle?: string;
    sendStageDescription?: string;
    verifyStageTitle?: string;
    verifyStageDescription?: string;
    skipOnboarding?: boolean;
    currentStage?: PasswordlessAuthStage,
    email?: string,
    signupReason?: SignupReason,
    action?: string,
    actionParams?: { [key: string]: any }
}

const DEFAULT_AUTH_OPTIONS: Partial<Auth0LockPasswordlessConstructorOptions> = {
    theme: {
        logo: "https://res.cloudinary.com/dap6pju8g/image/upload/v1694174113/crewstories-logo-black_q9jrda.png",
        primaryColor: "#D3712C",
        hideMainScreenTitle: true
    },
    auth: {
        redirect: environment.redirectOnAuth,
        audience: environment.auth.audience,
        responseType: 'token id_token',
        params: {
            scope: 'openid profile email'
        }
    }
};


export interface PasswordlessLoginConfig {
    method: PasswordlessSendMethod;
    skipOnboarding: boolean;
    signupReason: SignupReason;
    redirectPath: string;
    action: string;
    actionParams?: { [key: string]: any }
}

export interface Auth0CodeVerificationResponse {
    access_token: string,
    id_token: string,
    scope: string,
    expires_in: number,
    token_type: string
    refresh_token?: string;
}


@Injectable({
    providedIn: 'root'
})
export class EmbeddedAuthService implements IAuthService {

    private _client: Auth0Client | undefined;
    private _lock: Auth0LockCore | undefined;
    private readonly _accessTokenStorageKey: string = "access_token";
    private readonly _idTokenStorageKey: string = "id_token";
    private readonly _userProfileStorageKey: string = "user_profile";
    private readonly _accessTokenCookieKey: string = "access_token"; // currently not used, use only if necessary for SSR
    private readonly _idTokenCookieKey: string = "id_token"; // currently not used, use only if necessary for SSR
    private readonly _refreshTokenStorageKey: string = "refresh_token";
    private _skipOnboarding = false;

    private _userProfile: UserProfile | undefined;
    private _accessToken: string | undefined;
    private _idToken: string | undefined;
    private _refreshToken: string | undefined;
    private _user: Auth0User | undefined;

    public accessToken$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    public refreshToken$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    public idToken$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    public userProfile$: BehaviorSubject<UserProfile | undefined> = new BehaviorSubject<UserProfile | undefined>(undefined);
    public user$: BehaviorSubject<Auth0User | undefined> = new BehaviorSubject<Auth0User | undefined>(undefined);
    public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onboardingCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public browserAuthCheckPassed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly _persistentStateKey = "AnonymousPersistentState";

    get accessToken(): string | undefined {
        return this._accessToken;
    }

    set accessToken(value: string | undefined) {
        this._accessToken = value;
        localStorage.setItem(this._accessTokenStorageKey, value || "");
        this.accessToken$.next(value);
    }

    get refreshToken(): string | undefined {
        return this._refreshToken;
    }

    set refreshToken(value: string | undefined) {
        this._refreshToken = value;
        localStorage.setItem(this._refreshTokenStorageKey, value || "");
        this.refreshToken$.next(value);
    }

    get userProfile(): UserProfile | undefined {
        return this._userProfile;
    }

    set userProfile(value: UserProfile | undefined) {
        this._userProfile = value;
        if (value) {
            localStorage.setItem(this._userProfileStorageKey, JSON.stringify(value));
        }
        else {
            localStorage.removeItem(this._userProfileStorageKey);
        }
        this.userProfile$.next(value);
    }

    get idToken(): string | undefined {
        return this._idToken;
    }

    set idToken(value: string | undefined) {
        this._idToken = value;
        localStorage.setItem(this._idTokenStorageKey, value || "");
        this.idToken$.next(value);
    }

    get user(): Auth0User | undefined {
        return this._user;
    }

    set user(value: Auth0User | undefined) {
        this._user = value;
        this.user$.next(value);
    }

    set skipOnboarding(value: boolean) {
        this._skipOnboarding = value;
    }

    constructor(
        private _platformService: PlatformService,
        private _http: HttpClient,
        private _modalService: ModalService,
        private _userProfileService: PublicUserProfileService,
        private _libraryStateService: LibraryStateService,
        private _utilitiesService: UtilitiesService,
        private _anonymousState: AnonymousPersistentState
    ) {
        if (this._platformService.isBrowser()) {
            this.checkAuthStatus();
        }
    }

    async startPasswordlessLogin(email: string, config: Partial<PasswordlessLoginConfig>): Promise<void> {
        console.log("Start Passwordless Login", JSON.stringify(config));
        let defaultConfig: PasswordlessLoginConfig = {
            method: "code",
            skipOnboarding: false,
            signupReason: { type: "generic" },
            redirectPath: this._platformService.getLocation()?.pathname ?? "/",
            action: "",
            actionParams: undefined
        };
        let { method, skipOnboarding, signupReason, redirectPath } = { ...defaultConfig, ...config };
        this._skipOnboarding = skipOnboarding;
        this._anonymousState.saveSignupReason(signupReason);
        const url = `https://${environment.auth.domain}/passwordless/start`;
        const authParams: any = {
            redirect: redirectPath,
            skipOnboarding: skipOnboarding ? 1 : undefined
        };
        if (config.action) authParams.action = config.action; // don't attempt to pass the action as a query param to the redirect path. Auth0 encoding issues
        if (config.actionParams) authParams.actionParams = await this._utilitiesService.objectToBase64(config.actionParams);

        const anonState = await this._anonymousState.clone(true);
        if (anonState) {
            authParams.state = anonState;
        }
        const body = {
            client_id: environment.auth.clientId,
            connection: 'email',
            email: email,
            send: method,
            authParams: authParams
        };
        await firstValueFrom(this._http.post(url, body))
    }

    async verifyCode(email: string, verificationCode: string, magicData: {[key: string]: any}): Promise<Partial<AuthResult>> {
        try {
            const url = `https://${environment.auth.domain}/oauth/token`;
            const body = {
                grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
                client_id: environment.auth.clientId,
                username: email,
                otp: verificationCode,
                realm: 'email',
                audience: environment.auth.audience,
                scope: 'openid profile email offline_access',
                magicData
            };
            let res = await firstValueFrom(this._http.post<Auth0CodeVerificationResponse>(url, body));
            let authResult: Partial<AuthResult> = {
                accessToken: res.access_token,
                idToken: res.id_token,
                refreshToken: res.refresh_token
            }
            await this.onAuthenticated(authResult);
            return authResult;
        }
        catch(e) {
            await this.logClientAuthError(email, verificationCode, e);
            throw e;
        }
    }

    async hasValidStoredUser(): Promise<boolean> {
        if (!this._platformService.isBrowser()) return false;
        try {
            let accessToken = localStorage.getItem(this._accessTokenStorageKey) as string;
            let idToken = localStorage.getItem(this._idTokenStorageKey) as string;
            let idTokenPayload = this.parseToken(idToken);
            let hasExpired = this.hasTokenExpired(accessToken);
            if (hasExpired) {
                try {
                    const newTokens = await this.refreshTokens(false);
                    accessToken = newTokens.accessToken;
                    idToken = newTokens.idToken;
                    idTokenPayload = this.parseToken(idToken);
                    hasExpired = false;                
                }
                catch {
                    return false;
                }
            }
            let refreshToken = localStorage.getItem(this._refreshTokenStorageKey) as string;
            if (accessToken && idToken && idTokenPayload && !hasExpired) {
                return true;
            } else {
                return false;
            }
        } catch (_) {
            return false;
        }
    }

    private async checkAuthStatus() {
        if (this._platformService.isBrowser()) {
            if ((await this.checkStorage())) {
                this.isLoggedIn$.next(true);
            }
            else {
                this.isLoggedIn$.next(false);
            }
            this.browserAuthCheckPassed$.next(true);
        }
        else {
            this.browserAuthCheckPassed$.next(false);
        }
    }

    private async checkStorage() {
        try {
            if (this._platformService.isBrowser()) {
                let accessToken = localStorage.getItem(this._accessTokenStorageKey) as string;
                let idToken = localStorage.getItem(this._idTokenStorageKey) as string;
                let idTokenPayload = this.parseToken(idToken);
                let hasExpired = this.hasTokenExpired(accessToken);
                if (hasExpired) {
                    try {
                        const newTokens = await this.refreshTokens(false);
                        accessToken = newTokens.accessToken;
                        idToken = newTokens.idToken;
                        idTokenPayload = this.parseToken(idToken);
                        hasExpired = false;                
                    }
                    catch {
                        return false;
                    }
                }
                let refreshToken = localStorage.getItem(this._refreshTokenStorageKey) as string;
                if (accessToken && idToken && idTokenPayload && !hasExpired) {
                    this._skipOnboarding = true; //disable onboarding on load
                    await this.onAuthenticated({
                        accessToken: accessToken,
                        idToken: idToken,
                        idTokenPayload: idTokenPayload,
                        refreshToken: refreshToken || ""
                    });
                    return true;
                }
                return false;
            }
            return false;
        }
        catch {
            return false;
        }
    }

    async ensureOnboardingCompleted(): Promise<void> {
        if (await firstValueFrom(this.onboardingCompleted$)) return;
        return new Promise((resolve) => {
            const sub = this.onboardingCompleted$.subscribe((completed) => {
                if (completed) {
                    sub.unsubscribe();
                    resolve();
                }
            });
            this.triggerNameChangeModal();
        });
    }

    // Just renders the authentication modal, does not respond with authentication data
    renderAuthModal(options: AuthModalOptions = {}) {
        const el = this._modalService.open(PasswordlessAuthComponent, {
            showModalHeader: true,
            showCloseButton: true,
            centered: true,
            dialogClass: "signup-modal-dialog",
            childProps: {
                ...options,
                authSuccess: (authResult: Partial<AuthResult>) => {
                    this._modalService.close();
                },
                stageChange: (stage: PasswordlessAuthStage) => {
                    if (stage == PasswordlessAuthStage.Verify) {
                        el?.children[0].classList.remove("signup-modal-dialog");
                        el?.children[0].classList.add("verify-modal-dialog");
                    }
                    else {
                        el?.children[0].classList.remove("verify-modal-dialog");
                        el?.children[0].classList.add("signup-modal-dialog");
                    }
                }
            }
        });
    }

    // Waits for the login operation to complete and returns the user. Only use it if you want to be notified immediately after the user logs in
    login(options: AuthModalOptions = {}): Promise<Auth0User> {
        return new Promise(async (resolve, reject) => {
            // skip the default state
            let s = this.user$.subscribe(user => {
                if (user) {
                    resolve(user);
                    s.unsubscribe();
                }
                else {
                    this.renderAuthModal(options);
                }
            });
        });
    }

    async logout() {
        if (!this._platformService.isBrowser()) return;
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.clearAllCookies();
        if (window.location.href.includes("/account-settings") || window.location.href.includes("/library")) {
            window.location.href = this._platformService.getHomeUrl() as string;
        } else {
            const goTo = window.location.href.split("?")[0];
            if (window.location.href === goTo) {
                window.location.reload();
            } else {
                window.location.href = goTo;
            }
        }
    }

    async refreshTokens(invokeCallback: boolean = true): Promise<{ accessToken: string, idToken: string, refreshToken: string }> {
        try {
            const url = `${environment.baseUrl}/api/public/user-profile/token`;
            let res = await firstValueFrom(this._http.post<{ access_token: string, id_token: string, refresh_token: string }>(url, {}));
            let tokens = {
                accessToken: res.access_token,
                idToken: res.id_token,
                refreshToken: res.refresh_token
            }
            if (invokeCallback) {
                await this.onAuthenticated(tokens);
            }
            return tokens;
        }
        catch (e) {
            localStorage.clear();
            this.isLoggedIn$.next(false);
            throw e;
        }
    }

    async logClientAuthError(email: string, code: string, err?: any) {
        try {
            const url = `${environment.baseUrl}/api/auth/err`;
            await firstValueFrom(this._http.post(url, {
                email,
                code,
                err
            }));
        }
        catch (e) {
            console.error("Error logging auth error: ", e);
        }
    }

    async changeName(name: string) {
        try {
            await this._userProfileService.changeName(name);
        } catch (e) {
            console.error("Error changing name: ", e);
        } finally {
            await this._userProfileService.updateOnboardingStage(OnboardingStage.Completed);
            //manual token + user refresh instead of refreshTokens() (it calls onAuthenticated() and puts us in a loop)
            const res = await firstValueFrom(this._http.post<{access_token: string, id_token: string, refresh_token: string}>(`${environment.baseUrl}/api/public/user-profile/token`, {}));
            this.idToken = res.id_token;
            this.accessToken = res.access_token;
            if (res.refresh_token) this.refreshToken = res.refresh_token;
            this.user = this.parseToken(this.idToken);
            this.userProfile = await this._userProfileService.getUserMetadata();
            this.onboardingCompleted$.next(true);
            this._modalService.close();
        }
    }

    private clearAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
        }
    }

    private async onAuthenticated(authResult: Partial<AuthResult>) {
        this._lock?.hide();
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
        if (authResult.refreshToken) {
            this.refreshToken = authResult.refreshToken;
        }
        this.user = (authResult && authResult.idTokenPayload) ? authResult.idTokenPayload as Auth0User : this.parseToken(authResult.idToken as string);
        this.userProfile = await this._userProfileService.getUserMetadata();
        if (this.userProfile?.onboardingStage == OnboardingStage.Name) {
            if (!this._skipOnboarding) {
                this.triggerNameChangeModal();
            }
        }
        else if (this.userProfile?.onboardingStage == OnboardingStage.Completed) {
            this.onboardingCompleted$.next(true);
        }
        this.isLoggedIn$.next(true);
        this.initUserState();
    }

    private initUserState() {
        this._libraryStateService.init();
    }

    private async triggerNameChangeModal() {
        this._modalService.open(ChangeNameComponent, {
            showModalHeader: false,
            showCloseButton: false,
            centered: true,
            closable: false,
            childProps: {
                name: this.userProfile?.name,
                save: async (name: string) => {
                    await this.changeName(name);
                }
            },
            afterDismiss: async () => {
                try {
                    if (!(await firstValueFrom(this.onboardingCompleted$))) {
                        await this._userProfileService.updateOnboardingStage(OnboardingStage.Completed);
                        this._modalService.close();
                        this.onboardingCompleted$.next(true);
                    }
                }
                catch (e) {
                    console.error("Error changing name after dismiss:", e);
                }
            }
        })
    }

    private parseToken(token: string): Auth0User | undefined {
        return jwtDecode(token) as Auth0User;
    }

    private hasTokenExpired(token: string): boolean {
        let parsedToken = this.parseToken(token);
        return (parsedToken && parsedToken.exp && dayjs().isAfter(dayjs.unix(parsedToken.exp))) ? true : false;
    }
}