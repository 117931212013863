import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, map } from 'rxjs';
import { Genre } from '../models/genre.model';
import { environment } from 'src/environments/environment';
import { SubGenre } from '../models/sub-genre.model';
import { BooksService } from './books.service';
import { PaginatedBookResponse } from '../models/paginated-response.model';

@Injectable({
    providedIn: 'root',
})
export class GenresService {
    private apiUrl = `${environment.baseUrl}/api/genres`;
    private subGenresUrl = `${environment.baseUrl}/api/subgenres`;

    constructor(
        private http: HttpClient,
        private booksService: BooksService
    ) { }

    findAllGenres(): Observable<Genre[]> {
        return this.http.get<Genre[]>(`${this.apiUrl}?activeOnly=1`);
    }

    findAllSubGenres(): Observable<SubGenre[]> { 
        return this.http.get<SubGenre[]>(`${this.subGenresUrl}?activeOnly=1`);
    }

    findWithBooksBySlug(slug: string, page = 1): Observable<PaginatedBookResponse<Genre>> {
        return this.http.get<PaginatedBookResponse<Genre>>(`${this.apiUrl}/slug/${slug}/books?page=${page}`);
    }

    findSubGenreWithBooksBySlug(slug: string): Observable<PaginatedBookResponse<SubGenre>> {
        return this.http.get<PaginatedBookResponse<SubGenre>>(`${this.subGenresUrl}/slug/${slug}/books`);
    }

    findOneByName(name: string): Observable<Genre> {
        const params = new HttpParams().set('name', name);
        return this.http.get<Genre>(`${this.apiUrl}/name`, { params });
    }

    findOneBySlug(slug: string): Observable<Genre> {
        return this.http.get<Genre>(`${this.apiUrl}/slug/${slug}`);
    }
}
