import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[backgroundColor]"
})
export class BackgroundColorDirective implements OnChanges {
    @Input("backgroundColor") backgroundColor!: string;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["backgroundColor"] && this.backgroundColor) {
            if (this.backgroundColor.includes("gradient")) {
                this.renderer.removeStyle(this.el.nativeElement, "backgroundColor");
                this.renderer.setStyle(
                    this.el.nativeElement,
                    "background",
                    this.backgroundColor
                );
            }
            else {
                this.renderer.removeStyle(this.el.nativeElement, "background");
                this.renderer.setStyle(
                    this.el.nativeElement,
                    "backgroundColor",
                    this.backgroundColor
                );
            }
        }
        else {
            this.renderer.removeStyle(this.el.nativeElement, "backgroundColor");
        }
    }
}
