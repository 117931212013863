import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ReadablesService } from 'src/app/modules/readables/services/readables.service';
import { Readable } from 'src/app/models/readable.model';
import { Book } from 'src/app/models/book.model';
import { Author } from 'src/app/models/author.model';
import { BookExtras } from 'src/app/models/book-extras.model';
import { firstValueFrom } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { TitleService } from 'src/app/services/title.service';
import { JsonLdService } from 'src/app/services/json-ld.service';
import { CanonicalService } from 'src/app/services/canonical.service';
import { BooksService } from 'src/app/services/books.service';
import { environment } from 'src/environments/environment';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { ReadablesReaderPopUpV2 } from 'src/app/modules/readables/components/reader-popup-v2/reader-popup-v2.component';
import { CharacterOverviewsComponent } from 'src/app/modules/books/components/books/character-overviews/character-overviews.component';

@Component({
    selector: 'bonus-page',
    templateUrl: './bonus-page.component.html',
    styleUrls: ['./bonus-page.component.scss']
})
export class BonusPageComponent implements OnInit {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly _readablesService: ReadablesService,
        private readonly _booksService: BooksService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _titleService: TitleService,
        private readonly _jsonLdService: JsonLdService,
        private readonly _canonicalService: CanonicalService,
        private readonly _anonymousPersistentState: AnonymousPersistentState
    ) {}

    readable!: Readable;
    book!: Book;
    author!: Author;
    characters!: BookExtras["data"]["characters"];
    mode!: "bonus_scene" | "character_overviews";

    @ViewChild(ReadablesReaderPopUpV2) readerPopup!: ReadablesReaderPopUpV2;
    @ViewChild(CharacterOverviewsComponent) characterOverviewsPopup!: CharacterOverviewsComponent;

    readonly animationImage = "https://res.cloudinary.com/dap6pju8g/image/upload/fl_lossy/q_100/c_scale,h_225/v1730205745/book-media/cvoaey9yl5dtbbkyd1z1.gif";

    get bookPageUrl() {
        return `/authors/${this.author.slug}/books/${this.book.slug}`;
    }

    async ngOnInit() {
        this._platformService.metaTags.setNoIndex();

        this._utilitiesService.preloadImage(this.animationImage);

        const params = await firstValueFrom(this._route.params);
        const bookSlug = params["book-slug"];
        if (!bookSlug) {
            this._router.navigate(["/"]);
            return;
        }
        try {
            //check first if the book has a bonus_scene and use that, if not go to character_overviews mode
            const bs = await firstValueFrom(this._readablesService.infoByBookSlug(bookSlug, "bonus_scene"));
            if (bs) {
                this.readable = bs;
                this.book = this.readable.book!;
                this.author = this.readable.author!;
                this.mode = "bonus_scene";
            } else {
                const extras = await firstValueFrom(this._booksService.getBookExtras(bookSlug, "characters"));
                if (!extras) {
                    //no bonus_scene or character overviews, abort
                    this._router.navigate(["/"]);
                    return;
                }
                this.characters = extras.data.characters;
                if (!this.characters || !this.characters.length) {
                    //no characters
                    this._router.navigate(["/"]);
                    return;
                }
                this.book = await firstValueFrom(this._booksService.findOneBySlug(bookSlug));
                this.author = this.book.authors.find(a => a.slug === this.book.mainAuthorSlug)!;
                this.mode = "character_overviews";
            }
        } catch (_) {
            this._router.navigate(["/"]);
            return;
        }
        this._addMetaTags();

        if (this._platformService.isBrowser()) {
            const bookSrc = (await firstValueFrom(this._route.queryParamMap)).get("book_src");
            //sending it as an event instead of a page view
            this._analyticsService.track({
                event: "init_bonus_content_flow",
                params: {
                    book: this.book,
                    author: this.author,
                    flowAlreadyCompleted: !!this._anonymousPersistentState.bonusContent[this.book.slug],
                    kindle: false,
                    bookSrc: bookSrc || undefined,
                    contentType: this.mode
                }
            });
            //auto trigger the popup if they have unlocked it already
            if (this._anonymousPersistentState.bonusContent[this.book.slug]) {
                if (this.mode === "bonus_scene") {
                    let done = false;
                    const timer = setInterval(async () => {
                        if (this.readerPopup) {
                            if (done) return;
                            done = true;
                            await this.readerPopup.startReading();
                            await this.startFlow();
                            clearTimeout(timer);
                        }
                    }, 50);
                } else {
                    let done = false;
                    const timer = setInterval(async () => {
                        if (this.characterOverviewsPopup) {
                            if (done) return;
                            done = true;
                            await this.characterOverviewsPopup.openCharacterModal();
                            await this.startFlow();
                            clearTimeout(timer);
                        }
                    }, 50);
                }
            }
        }
    }

    async startFlow() {
        this._analyticsService.track({
            event: "start_bonus_content_flow",
            params: {
                book: this.book,
                author: this.author,
                flowAlreadyCompleted: !!this._anonymousPersistentState.bonusContent[this.book.slug],
                kindle: false,
                contentType: this.mode,
                triggeredFrom: "bonus_page"
            }
        });
        await this._utilitiesService.wait(2000);
        this._router.navigate([this.bookPageUrl]);
    }

    private _addMetaTags() {
        const title = this.mode === "bonus_scene" ? `${this.book.title} - Bonus Scene` : `${this.book.title} - Bonus Content` ;
        this._titleService.setTitle(title);
        this._platformService.metaTags.setTitle(title);
        this._platformService.metaTags.setDescription(this.book.description!);
        this._platformService.metaTags.setOpenGraphImage(this.book.images.cover);
        this._jsonLdService.addWebPage({
            name: title,
            url: this._platformService.currentUrl,
            description: this.book.description!,
        });
        this._canonicalService.set(`${environment.baseUrl}${this.bookPageUrl}`);
    }
}