<ng-container *ngIf="spicyMeter && spicyMeter >= 1 && spicyMeter <= 5">
    <div class="spicy-container">
        <span *ngIf="!onlyPeppers" class="level">{{guide[spicyMeter - 1].label}}</span>
        <div class="peppers">
            <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
                <ng-container *ngIf="spicyMeter >= i" [ngTemplateOutlet]="pepperRed"></ng-container>
                <ng-container *ngIf="spicyMeter < i" [ngTemplateOutlet]="pepperGray"></ng-container>
            </ng-container>
        </div>
        <feather-icon *ngIf="!onlyPeppers" icon="info" width="20" height="20" (click)="openLevelGuide()"></feather-icon>
    </div>
</ng-container>

<ng-template #pepperRed>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.761 2.84203C11.6364 2.20086 11.2929 1.62291 10.7892 1.20708C10.2855 0.791249 9.65295 0.563392 8.99979 0.5625C8.8506 0.5625 8.70753 0.621763 8.60204 0.727252C8.49655 0.832742 8.43729 0.975816 8.43729 1.125C8.43729 1.27418 8.49655 1.41726 8.60204 1.52275C8.70753 1.62824 8.8506 1.6875 8.99979 1.6875C9.35712 1.6875 9.70522 1.80093 9.99396 2.01145C10.2827 2.22196 10.4971 2.5187 10.6064 2.85891C9.53606 3.01471 8.55745 3.55037 7.84937 4.36805C7.14129 5.18573 6.751 6.23085 6.74979 7.3125C6.74979 10.5996 4.93924 12.7969 1.36877 13.8516C1.12455 13.9228 0.911656 14.0746 0.764844 14.2824C0.618033 14.4901 0.545968 14.7415 0.560409 14.9955C0.57485 15.2495 0.674944 15.491 0.844358 15.6808C1.01377 15.8706 1.2425 15.9973 1.49323 16.0404C2.55004 16.2231 3.62066 16.3141 4.69315 16.3125C7.56049 16.3125 10.7513 15.6691 12.957 13.8192C14.8104 12.2653 15.7498 10.0758 15.7498 7.3125C15.7486 6.20791 15.3417 5.14226 14.6063 4.31806C13.8709 3.49386 12.8583 2.96857 11.761 2.84203ZM13.4998 6.67969L11.5015 5.68336C11.4234 5.64425 11.3372 5.62389 11.2498 5.62389C11.1624 5.62389 11.0762 5.64425 10.9981 5.68336L8.99979 6.67969L8.05971 6.21C8.28893 5.54635 8.71942 4.97071 9.29122 4.56326C9.86302 4.1558 10.5477 3.93683 11.2498 3.93683C11.9519 3.93683 12.6366 4.1558 13.2084 4.56326C13.7802 4.97071 14.2106 5.54635 14.4399 6.21L13.4998 6.67969Z" fill="#F44336"/>
    </svg>
</ng-template>

<ng-template #pepperGray>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.761 2.84203C11.6364 2.20086 11.2929 1.62291 10.7892 1.20708C10.2855 0.791249 9.65295 0.563392 8.99979 0.5625C8.8506 0.5625 8.70753 0.621763 8.60204 0.727252C8.49655 0.832742 8.43729 0.975816 8.43729 1.125C8.43729 1.27418 8.49655 1.41726 8.60204 1.52275C8.70753 1.62824 8.8506 1.6875 8.99979 1.6875C9.35712 1.6875 9.70522 1.80093 9.99396 2.01145C10.2827 2.22196 10.4971 2.5187 10.6064 2.85891C9.53606 3.01471 8.55745 3.55037 7.84937 4.36805C7.14129 5.18573 6.751 6.23085 6.74979 7.3125C6.74979 10.5996 4.93924 12.7969 1.36877 13.8516C1.12455 13.9228 0.911656 14.0746 0.764844 14.2824C0.618033 14.4901 0.545968 14.7415 0.560409 14.9955C0.57485 15.2495 0.674944 15.491 0.844358 15.6808C1.01377 15.8706 1.2425 15.9973 1.49323 16.0404C2.55004 16.2231 3.62066 16.3141 4.69315 16.3125C7.56049 16.3125 10.7513 15.6691 12.957 13.8192C14.8104 12.2653 15.7498 10.0758 15.7498 7.3125C15.7486 6.20791 15.3417 5.14226 14.6063 4.31806C13.8709 3.49386 12.8583 2.96857 11.761 2.84203ZM13.4998 6.67969L11.5015 5.68336C11.4234 5.64425 11.3372 5.62389 11.2498 5.62389C11.1624 5.62389 11.0762 5.64425 10.9981 5.68336L8.99979 6.67969L8.05971 6.21C8.28893 5.54635 8.71942 4.97071 9.29122 4.56326C9.86302 4.1558 10.5477 3.93683 11.2498 3.93683C11.9519 3.93683 12.6366 4.1558 13.2084 4.56326C13.7802 4.97071 14.2106 5.54635 14.4399 6.21L13.4998 6.67969Z" fill="#D0D0D0"/>
    </svg>
</ng-template>

<ng-template #levelGuide let-modal>
    <div class="modal-body">
        <div class="guide-header d-flex flex-column">
            <div class="d-flex justify-content-end w-100">
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                ></button>
            </div>
            <div class="d-flex justify-content-center w-100 pepper-header">
                <img 
                    [src]="'https://res.cloudinary.com/dap6pju8g/image/upload/v1727270129/book-media/wdlk4ipzu56jvz3oo0ib.png' | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleWidth:120 | cloudinaryUrl"
                    >
            </div>
        </div>
        <div class="guide d-flex flex-column">
            <h6 class="header c-mb-10">Spicy meter explained</h6>
            <p class="description c-mb-30">
                Hey, bookworms! From a simmer to a wildfire, find the right heat for your next read in this detailed guide—based on explicit details, frequency, and intensity of the scenes.
            </p>
            <ng-container *ngFor="let current of guide">
                <div class="spicy d-flex c-mb-10">
                    <spicy-meter [spicyMeter]="current.level" [onlyPeppers]="true"></spicy-meter>
                    <span class="level c-ms-10">{{current.label}}</span>
                </div>
                <p class="description c-mb-20" [innerHTML]="current.description"></p>
            </ng-container>
        </div>
    </div>
</ng-template>
