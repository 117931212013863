import { Component, Input, AfterViewInit, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Book } from 'src/app/models/book.model';
import { BookSeries } from 'src/app/models/book-series.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { FakeAuthService } from 'src/app/modules/auth/services/fake-auth.service';

type PrelaunchPersistentState = {
    email: string;
    pages: {
        [bookRoute: string]: {
            firstStepCompleted: boolean;
            flowCompleted: boolean;
            firstTimeModalOpen: boolean;
        }
    }
};

@Component({
    selector: 'worldmaker-component-prelaunch-cover-header',
    templateUrl: './prelaunch-cover-header.component.html',
    styleUrls: ['./prelaunch-cover-header.component.scss'],
})
export class PrelaunchCoverHeader implements AfterViewInit, OnInit {
    @Input() data!: {
        book: Book;
        ctaText: string;
        headerText: string;
        useCustomCover: boolean;
        customCover: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        }
    };
    book!: Book;
    bookSeries?: BookSeries;
    bookHeight: number = 450;

    email = "";
    scrolled = false;
    firstStepCompleted = false;
    flowCompleted = false;
    firstTimeModalOpen = true;

    @ViewChild('joinModal') joinModal!: TemplateRef<any>;

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _authService: FakeAuthService,
        private modalService: NgbModal
    ) {}

    async ngOnInit() {
        this.book = this.data.book;
        if (await firstValueFrom(this._platformService.isMobile)) {
            this.bookHeight = 230;
        }
        this.restoreState();
        if (this.email) {
            this._analyticsService.identify(this.email);
        }
        const email = (await firstValueFrom(this._route.queryParamMap)).get("email");
        if (email) {
            this.email = email;
            this._analyticsService.identify(email);
            this._authService.save(email);
            this.saveState();
        }
        this._authService.afterLoadCb = (user) => {
            this.email = user.email;
            if (user.actions.find(a => a.action === "join_book_crew")) {
                this.flowCompleted = true;
            }
            this.saveState();
        }
        this._authService.afterActionCb = (action) => {
            if (action.action === "join_book_crew" && action.book === this.book.slug && action.author === this.book.mainAuthorSlug) {
                this.flowCompleted = true;
                this.saveState();
            }
        }
        this._analyticsService.trackPageView(undefined, "Book Page", {
            book: this.book.slug,
            author: this.book.mainAuthorSlug,
            mode: this.book.isPrelaunched ? "prelaunch" : "live",
            publisher: this.book.publisher || undefined
        });
    }

    async ngAfterViewInit() {
        if (await firstValueFrom(this._platformService.isMobile)) {
            this.bookHeight = 230;
        }
    }

    @HostListener("window:scroll")
    onWindowScroll() {
        const headerHeight = parseInt(getComputedStyle(document.querySelector(".cover-container")!).height.replace("px", ""));
        if (window.scrollY >= headerHeight) {
            this.scrolled = true;
        } else {
            this.scrolled = false;
        }
    }

    get bgCover() {
        if (this.data.useCustomCover && this.data.customCover) {
            return this.data.customCover;
        } else {
            return this.book.images.cover;
        }
    }

    get authorFirstName() {
        return this.book.mainAuthorName.split(" ")[0];
    }

    get authorFirstNameWithS() {
        const first = this.authorFirstName;
        if (first.endsWith("s")) {
            return `${first}'`;
        } else {
            return `${first}'s`;
        }
    }

    async readItFirst() {
        if (!this.email) return;
        this._analyticsService.identify(this.email);
        this._authService.save(this.email);
        const acValue = `${this.book.mainAuthorSlug}--${this.book.slug}`; //activeCampaign encode
        // this._analyticsService.trackEvent("prelaunch_read_it_first", {author: this.book.mainAuthorSlug, book: this.book.slug, acValue});
        if (!this.flowCompleted) {
            await this.openJoinModal();
        }
        this.firstStepCompleted = true;
        this.saveState();
    }

    async openModalAgain() {
        this.firstTimeModalOpen = false;
        await this.openJoinModal();
        this.saveState();
    }

    async openJoinModal() {
        await firstValueFrom(
            this.modalService.open(this.joinModal, {
                centered: false
            }).shown
        );
    }

    joinCrew() {
        // let mode = "";
        // if (this.book.slug === "secrets-of-the-duncan-clan-exclusive-collection") {
        //     mode = "own_prelaunch";
        // } else if (this.book.slug === "surrendering-to-the-daring-highlanders-collection") {
        //     mode = "own_launch";
        // }
        const acValue = `${this.book.mainAuthorSlug}--${this.book.slug}`; //activeCampaign encode
        // this._analyticsService.trackEvent("join_book_crew", {author: this.book.mainAuthorSlug, book: this.book.slug, acValue});
        this.flowCompleted = true;
        this._authService.save(this.email);
        this._authService.madeAction({action: "join_book_crew", author: this.book.mainAuthorSlug, book: this.book.slug});
        //scroll past the header when done
        const headerHeight = getComputedStyle(document.querySelector(".cover-container")!).height.replace("px", "");
        window.scroll(0, parseInt(headerHeight));
        this.modalService.dismissAll();
        this.saveState();
    }

    get crewImage() {
        //@TODO hardcoded stuff
        if (this.book.slug === "secrets-of-the-duncan-clan-exclusive-collection" || this.book.slug === "fantastic-beasts-and-where-to-find-them") {
            return "https://res.cloudinary.com/dap6pju8g/image/upload/v1710758018/book-media/fjtljlygkgqgiza61lb3.png";
        } else if (this.book.slug === "surrendering-to-the-daring-highlanders-collection" || this.book.slug === "lord-john-and-the-private-matter-a-novel-lord-john-grey-book-1") {
            return "https://res.cloudinary.com/dap6pju8g/image/upload/v1709287872/book-media/q9tywjdddyzluutw8bra.png";
        } else if (this.book.slug === "highland-hearts-entwined-exclusive-collection") {
            return "https://res.cloudinary.com/dap6pju8g/image/upload/v1711725380/book-media/rmwib2cqozodmitm9b58.png";
        } else if (this.book.slug === "legends-of-highland-destiny-alisa-adams") {
            return "https://res.cloudinary.com/dap6pju8g/image/upload/v1711725420/book-media/hluuqg9hua7ezmglyob4.png";
        } else {
            return "";
        }
    }

    restoreState() {
        if (!this._platformService.isBrowser()) return;
        try {
            if (!localStorage.getItem("PrelaunchPersistentState")) return;
            const state: PrelaunchPersistentState = JSON.parse(localStorage.getItem("PrelaunchPersistentState")!);
            if (state.email) {
                this.email = state.email;
                this._authService.save(state.email);
            }
            if (state.pages[this._router.url.split("?")[0]]) {
                const pageState = state.pages[this._router.url.split("?")[0]];
                this.firstStepCompleted = pageState.firstStepCompleted;
                this.flowCompleted = pageState.flowCompleted;
                this.firstTimeModalOpen = pageState.firstTimeModalOpen;
            }
        } catch (_e) {}
    }

    saveState() {
        if (!this._platformService.isBrowser()) return;
        try {
            let state: PrelaunchPersistentState = {email: "", pages: {}};
            if (localStorage.getItem("PrelaunchPersistentState")) {
                state = JSON.parse(localStorage.getItem("PrelaunchPersistentState")!);
            }
            state.email = this.email;
            state.pages[this._router.url.split("?")[0]] = {
                firstStepCompleted: this.firstStepCompleted,
                flowCompleted: this.flowCompleted,
                firstTimeModalOpen: this.firstTimeModalOpen
            };
            localStorage.setItem("PrelaunchPersistentState", JSON.stringify(state));
        } catch (_e) {}
    }
}
