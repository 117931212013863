import { Component, TemplateRef, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, filter, debounceTime, distinctUntilChanged } from 'rxjs';
import { SearchResult } from './search-result.model';
import { PlatformService } from 'src/app/services/platform.service';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Book } from 'src/app/models/book.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import * as dayjs from "dayjs";
import { HttpClient } from '@angular/common/http';
import { RecommendationsService } from 'src/app/services/recommendations.service';

@Component({
    selector: 'search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    animations: [
        trigger(
            'backdropTransition',
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.3s ease-in', style({ "opacity": 0.4 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ "opacity": 0.4 }),
                        animate('0.3s ease-out', style({ "opacity": 0 }))
                    ]
                )
            ]
        ),
        trigger(
            'searchControlTransition',
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.3s ease-in', style({ "opacity": 1 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ "opacity": 1 }),
                        animate('0.3s ease-out', style({ "opacity": 0 }))
                    ]
                )
            ]
        )
    ]
})
export class SearchBarComponent implements OnInit, AfterViewInit {
    constructor(
        private readonly http: HttpClient,
        private readonly _modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _router: Router,
        private readonly _analyticsService: AnalyticsService,
        private readonly _chatWidgetService: ChatWidgetService,
        private readonly _recommendationsService: RecommendationsService
    ) { }

    @ViewChild("mobileModal") mobileModal!: TemplateRef<any>;
    @ViewChild("desktopSearchContainer") desktopSearchContainer!: ElementRef<HTMLDivElement>;

    layoutConfig: ColumnLayoutConfig = {
        mobile: 3,
        sm: 3,
        md: 3,
        lg: 3
    }

    searchInputActive = false;
    desktopSearchActive = false;
    mobileModalController: NgbModalRef | null = null;
    activeCategory = "Books";
    lastQuery = "";
    noResultsQuery = "";

    searchForm = new FormGroup({
        q: new FormControl('', [Validators.required])
    });

    get queryControl(): FormControl {
        return this.searchForm.get("q") as FormControl;
    }

    results: SearchResult[] | null = null;

    defaultState: ContentGridDataSource<Book> | null = null;

    get bookResults(): ContentGridDataSource<SearchResult> | null {
        if (!this.results || !this.results.length) return null;
        return {
            data: this.results,
            title: b => b.title,
            subtitle: b => b.mainAuthorName,
            image: b => b.cover,
            //@ts-ignore
            url: b => this._utilitiesService.getBookUrl(b),
            onClick: (b) => {
                return () => {
                    this._analyticsService.track({ event: "search_result_click", params: { type: "book", entity: b } });
                }
            },
            badge: {
                type: "light",
                enabled: b => Boolean(b.isFree) || Boolean(b.isPrelaunched),
                text: b => {
                    if (b.isPrelaunched) {
                        return "COMING SOON";
                    }
                    else if (b.isFree) {
                        return "FREE BOOK";
                    }
                    else if (b.publishedAt && dayjs(b.publishedAt).isAfter(dayjs())) {
                        return "PRE-ORDER";
                    }
                    else {
                        return "";
                    }
                }
            },
            bottomBadge: b => this._getReleaseBadge(b)
        };
    }

    get seriesResults(): ContentGridDataSource<NonNullable<SearchResult["bookSeries"]>> | null {
        if (!this.results || !this.results.length) return null;
        const series: NonNullable<SearchResult["bookSeries"]>[] = [];
        for (const result of this.results) {
            if (!result.bookSeries) continue;
            if (!series.find(s => s.id === result.bookSeries!.id)) {
                series.push(result.bookSeries);
            }
        }
        if (!series.length) return null;
        return {
            data: series,
            title: s => s.title,
            subtitle: s => s.mainAuthorName,
            image: s => s.cover,
            //@ts-ignore
            url: s => this._utilitiesService.getBookSeriesUrl(s),
            onClick: (s) => {
                return () => {
                    this._analyticsService.track({ event: "search_result_click", params: { type: "series", entity: s } });
                }
            }
        }
    }

    get authorResults(): ContentGridDataSource<SearchResult["authors"][0]> | null {
        if (!this.results || !this.results.length) return null;
        const authors: SearchResult["authors"] = [];
        for (const result of this.results) {
            for (const author of result.authors) {
                if (!authors.find(a => a.id === author.id)) {
                    authors.push(author);
                }
            }
        }
        if (!authors.length) return null;
        return {
            data: authors,
            title: a => a.name,
            subtitle: _a => "",
            image: a => a.profile,
            //@ts-ignore
            url: a => this._utilitiesService.getAuthorUrl(a),
            onClick: (a) => {
                return () => {
                    this._analyticsService.track({ event: "search_result_click", params: { type: "author", entity: a } });
                }
            }
        }
    }

    get genreResults(): BadgeConfig[] | null {
        if (!this.results || !this.results.length) return null;
        const genres: SearchResult["genres"] = [];
        for (const result of this.results) {
            for (const genre of result.genres) {
                if (!genres.find(g => g.id === genre.id)) {
                    genres.push(genre);
                }
            }
        }
        if (!genres.length) return null;

        return genres.map(genre => {
            return {
                text: genre.name,
                type: "default",
                url: this._utilitiesService.getGenreUrl(genre.slug),
                onClick: () => {
                    this._analyticsService.track({ event: "search_result_click", params: { type: "genre", entity: genre } });
                }
            };
        });
    }

    get tropeResults(): BadgeConfig[] | null {
        if (!this.results || !this.results.length) return null;
        const tropes: SearchResult["tropes"] = [];
        for (const result of this.results) {
            for (const trope of result.tropes) {
                if (!tropes.find(t => t.id === trope.id)) {
                    tropes.push(trope);
                }
            }
        }
        if (!tropes.length) return null;

        return tropes.map(trope => {
            return {
                text: trope.name,
                type: "default",
                url: this._utilitiesService.getTropeUrl(trope.slug),
                onClick: () => {
                    this._analyticsService.track({ event: "search_result_click", params: { type: "trope", entity: trope } });
                }
            };
        });
    }

    get worldResults(): (SearchResult["worlds"][0] & { onClick: () => void })[] | null {
        if (!this.results || !this.results.length) return null;
        const worlds: (SearchResult["worlds"][0] & { onClick: () => void })[] = [];
        for (const result of this.results) {
            for (const world of result.worlds) {
                if (!worlds.find(w => w.id === world.id)) {
                    worlds.push({
                        ...world,
                        onClick: () => {
                            this._analyticsService.track({ event: "search_result_click", params: { type: "world", entity: world } });
                        }
                    });
                }
            }
        }
        if (!worlds.length) return null;
        return worlds;
    }

    get categories() {
        const categories: string[] = [];
        if (this.bookResults) {
            categories.push("Books");
        }
        if (this.seriesResults) {
            categories.push("Series");
        }
        if (this.authorResults) {
            categories.push("Authors");
        }
        if (this.genreResults) {
            categories.push("Genres");
        }
        if (this.tropeResults) {
            categories.push("Tropes");
        }
        if (this.worldResults) {
            categories.push("Worlds");
        }
        return categories;
    }

    async ngOnInit() {
        if (this._platformService.isServer()) return;
        const books = (await firstValueFrom(this._recommendationsService.getRecommendation("search", "new_and_hot"))).data.books;
        this.defaultState = {
            data: books.filter(b => b.isVisible).slice(0, 6),
            title: b => b.title,
            subtitle: b => b.mainAuthorName,
            image: b => b.images.cover,
            //@ts-ignore
            url: b => this._utilitiesService.getBookUrl(b),
            onClick: (b) => {
                return () => {
                    this._analyticsService.track({ event: "search_result_click", params: { type: "book", entity: { ...b, featured: true } } });
                    this._analyticsService.track({
                        event: "recommendation_click", params: {
                            book: b,
                            destinationType: "book_page",
                            placement: "search",
                            recommendationType: this._getReleaseBadge(b) || "Older Release",
                            currentUrl: this._router.url
                        }
                    });
                }
            },
            badge: {
                type: "light",
                enabled: b => Boolean(b.isFree) || Boolean(b.isPrelaunched),
                text: b => {
                    if (b.isPrelaunched) {
                        return "COMING SOON";
                    }
                    else if (b.isFree) {
                        return "FREE BOOK";
                    }
                    else if (b.publishedAt && dayjs(b.publishedAt).isAfter(dayjs())) {
                        return "PRE-ORDER";
                    }
                    else {
                        return "";
                    }
                }
            },
            bottomBadge: b => this._getReleaseBadge(b)
        };
    }

    ngAfterViewInit(): void {
        if (!this._platformService.isBrowser()) return;
        this.desktopSearchContainer.nativeElement.addEventListener("shown.bs.dropdown", _event => {
            this.desktopSearchActive = true;
        });
        this.desktopSearchContainer.nativeElement.addEventListener("hidden.bs.dropdown", _event => {
            this.desktopSearchActive = false;
        });
        this._router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe(_event => {
            this.stopAndClearSearch();
        });
        //search as you type
        this.queryControl.valueChanges.pipe(
            debounceTime(600),
            distinctUntilChanged()
        ).subscribe(async () => {
            await this.search();
        });
    }

    initDesktopSearch() {
        this.searchInputActive = true;
        this.desktopSearchActive = true;
        //@ts-ignore
        const dropdown = new bootstrap.Dropdown(this.desktopSearchContainer.nativeElement, {
            offset: "0, 15"
        });
        if (!dropdown._isShown()) {
            dropdown.show();
        }
        this._analyticsService.track({ event: "search_init" });
    }

    stopAndClearSearch() {
        this.clear();
        this.stopSearch();
    }

    stopSearch() {
        this.desktopSearchActive = false;
        //@ts-ignore
        (new bootstrap.Dropdown(this.desktopSearchContainer.nativeElement)).hide();
        if (this.mobileModalController) {
            this.mobileModalController.close();
        }
    }

    clear() {
        this.results = null;
        this.noResultsQuery = "";
        this.queryControl.setValue("");
    }

    async desktopSearch() {
        await this.search();
        this.initDesktopSearch();
    }

    async mobileSearch() {
        await this.search();
    }

    private async search() {
        if (!this.searchForm.valid) return;
        if (this.lastQuery === this.queryControl.value && this.results) return;

        const queryParams = {
            q: this.queryControl.value,
            limit: '6',
            limit_hits: '6',
            highlight_fields: "none",
            query_by: [
                "title", "asin", "isbns", "publisher",
                "bookSeries.title", "bookSeries.asin",
                "authors.name", "authors.asin",
                "genres.name",
                "subGenres.name",
                "tropes.name",
                "worlds.title",
                "description", "authors.bio", "authors.intro", "genres.description", "tropes.description", "worlds.description"
            ].join(","),
            exclude_fields: ["out_of", "search_time_ms"].join(","),
            filter_by: "isVisible:true"
        };

        const queryString = new URLSearchParams(queryParams).toString();

        try {
            const response: any = await firstValueFrom(this.http.get(
                `${environment.searchHost}/collections/books/documents/search?${queryString}`,
                { headers: { ['x-typesense-api-key']: environment.searchKey } }
            ));

            this.results = response.hits?.map((re: any) => re.document) as unknown as SearchResult[];

            if (!this.results || !this.results.length) {
                this.noResultsQuery = this.queryControl.value;
            } else {
                this.noResultsQuery = "";
            }

            this.lastQuery = this.queryControl.value;
            this.activeCategory = "Books";
            this._analyticsService.track({ event: "search_query", params: { query: this.queryControl.value } });

        } catch (error) {
            console.error("Search error:", error);
            this.noResultsQuery = this.queryControl.value;
        }
    }



    async openMobileSearch() {
        this._chatWidgetService.hide();
        const that = this;
        this.mobileModalController = this._modalService.open(this.mobileModal, {
            fullscreen: true,
            animation: false,
            scrollable: true,
            beforeDismiss() {
                that._chatWidgetService.show();
                return true;
            }
        });
        await firstValueFrom(this.mobileModalController.shown);
        this._analyticsService.track({ event: "search_init" });
    }

    private _getReleaseBadge(book: Book | SearchResult) {
        if (!book.publishedAt) return "";
        if (book.isPrelaunched || book.isFree) return "";
        
        // if (book.publisher !== "NB") return "";
        let publishedAt;
        if (typeof book.publishedAt === "number") {
            publishedAt = dayjs.unix(book.publishedAt);
        } else {
            publishedAt = dayjs(book.publishedAt);
        }

        if (!book.isPrelaunched && publishedAt.isAfter(dayjs())) {
            return "Pre-order";
        } else if (publishedAt.isAfter(dayjs().subtract(7, "days"))) {
            return "New Release";
        } else if ((publishedAt.isAfter(dayjs().subtract(14, "days")))) {
            return "Recent Release";
        }
        return "";
    }

    private _getNodeList() {
        const nodes: {
            host: string;
            port: number;
            protocol: string;
        }[] = [];
        for (const url of environment.searchHost.split(",")) {
            const host = new URL(url);
            const protocol = host.protocol.split(":")[0];
            let port = host.port;
            if (!port) {
                if (protocol === "http") {
                    port = "80";
                } else {
                    port = "443";
                }
            }
            nodes.push({
                host: host.hostname,
                port: parseInt(port),
                protocol: host.protocol.split(":")[0]
            });
        }

        return nodes;
    }
}