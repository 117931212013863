import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: "spicy-meter",
    templateUrl: "./spicy-meter.component.html",
    styleUrl: "./spicy-meter.component.scss"
})
export class SpicyMeterComponent {
    @Input() spicyMeter: number = 0;
    @Input() onlyPeppers = false;

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    @ViewChild("levelGuide") levelGuide!: TemplateRef<any>;

    guide = [{
        level: 1,
        label: "Sweet",
        description: `
            Tension and attraction simmer in the background, hugging and kissing are included, but there is absolutely no mention of sex. 
            <br>Common phrases: “kiss,” “hug,” “hold hands,” “embrace,” etc.
        `,
        rating: "Family Friendly/E Rating"
    }, {
        level: 2,
        label: "Sensual",
        description: `
            There is romantic tension, with light touching and love scenes that fade to black. No vulgar language or graphic content. A couple of superficial intimate scenes are included at most. 
            <br>Common phrases: “caress,” “brush of the lips,” “sparks on her skin,” “tingling,” etc.
        `,
        rating: "Teen/PG Rating"
    }, {
        level: 3,
        label: "Sexy",
        description: `
            A few non-descriptive sexual scenes, with allusions and innuendos. More traditionally modest and emotionally impactful scenes.
            <br>Common phrases: "her desire," "taste of his kiss," "tightness in her stomach," etc.
        `,
        rating: "Young Adult/PG 13+ Rating"
    }, {
        level: 4,
        label: "Steamy",
        description: `
            Intense sexual tension and more in-depth intimacy between the protagonists. No vulgar language was used, emotion-oriented sexual scenes. A handful of explicit scenes.
            <br>Common phrases: "his manhood," "his member," "her core," "wet heat," etc.
        `,
        rating: "New Adult/M Rating"
    }, {
        level: 5,
        label: "Spicy",
        description: `
            Detailed description of physical intimacy, more vulgar and explicit language, exploring tropes like forbidden love, enemies-to-lovers and age-gap romance. Elements of dark romance can also be included. May contain more than one explicit scene. 
            <br>Common phrases: f**k, d**k, c**k, c**m etc.
        `,
        rating: "Adult/R Rating"
    }];

    async openLevelGuide() {
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this._modalService.open(this.levelGuide, {
                size: "lg",
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                beforeDismiss() {
                    that._chatWidgetService.show();
                    return true;
                },
            }).shown
        );
    }
}
