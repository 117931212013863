<div *ngIf="mode == 'default'" class="skeleton"></div>

<!-- Library Page (based on Free book design) -->
<div *ngIf="mode == 'library'" class="card library-skeleton">
    <div class="card-body d-flex">
        <div class="skeleton skeleton-cover"></div>
        <div class="d-flex flex-column justify-content-between">
            <div class="d-flex flex-column skeleton-group-header">
                <div class="skeleton skeleton-title big"></div>
                <div class="skeleton skeleton-title"></div>
                <div class="skeleton skeleton-author"></div>
            </div>
            <div class="skeleton skeleton-cta"></div>
        </div>
    </div>
</div>

<div class="read-lines" *ngIf="mode === 'read-lines'">
    <div class="big skeleton line"></div>
    <div class="small skeleton line"></div>

    <div class="small skeleton line"></div>
    <div class="big skeleton line"></div>

    <div class="full skeleton line"></div>
</div>

<div class="book-row" *ngIf="mode === 'book-row'">
    <div class="skeleton skeleton-title"></div>
    <div class="books">
        <div class="skeleton skeleton-cover"></div>
        <div class="skeleton skeleton-cover"></div>
        <div class="skeleton skeleton-cover"></div>
        <div class="skeleton skeleton-cover"></div>
        <div class="skeleton skeleton-cover"></div>
        <div class="skeleton skeleton-cover"></div>
    </div>
</div>

<div class="badges" *ngIf="mode === 'badges'">
    <div class="skeleton skeleton-title"></div>
    <div class="entries">
        <div class="skeleton small"></div>
        <div class="skeleton small"></div>
        <div class="skeleton small"></div>

        <div class="skeleton big"></div>
        <div class="skeleton big"></div>

        <div class="skeleton big"></div>
        <div class="skeleton big"></div>

        <div class="skeleton small"></div>
        <div class="skeleton small"></div>
        <div class="skeleton small"></div>

        <div class="skeleton small"></div>
        <div class="skeleton small"></div>
        <div class="skeleton small"></div>


        <div class="skeleton big"></div>
        <div class="skeleton big"></div>

        <div class="skeleton small"></div>
        <div class="skeleton small"></div>
        <div class="skeleton small"></div>
    </div>
</div>